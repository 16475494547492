import { createSlice } from '@reduxjs/toolkit';

import {
  getUserById,
  getUsers,
  updateUser,
  updateApproval,
  updateUserRole,
  getCalendarList,
} from '../thunks/usersThunks';
import { setLoadingOff, setLoadingOn } from './helpers';

const initialState = {
  isLoading: false,
  pagination: null,
  data: [],
  calendarList: []
};

const onUserUpdate = (state, { payload }) => {
  state.data = state.data.map(user =>
    user.id === payload.id ? payload : user
  );
  state.isLoading = false;
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  extraReducers: {
    [getUsers.fulfilled]: (state, { payload }) => {
      state.data = payload['hydra:member'];
      state.pagination = payload['hydra:view'];
      state.isLoading = false;
    },
    [getUserById.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.data.push(payload);
    },
    [updateUser.fulfilled]: onUserUpdate,
    [updateApproval.fulfilled]: onUserUpdate,
    [updateUserRole.fulfilled]: onUserUpdate,
    [getUsers.pending]: setLoadingOn,
    [getUserById.pending]: setLoadingOn,
    [updateUser.pending]: setLoadingOn,
    [updateUserRole.pending]: setLoadingOn,
    [getUsers.rejected]: setLoadingOff,
    [getUserById.rejected]: setLoadingOff,
    [updateUser.rejected]: setLoadingOff,
    [updateUserRole.rejected]: setLoadingOff,
    [getCalendarList.fulfilled]: (state, { payload }) => {  
      state.calendarList = payload['hydra:member'];
    }
  },
});

export default usersSlice.reducer;
