const catcher = f => async (arg, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;

  try {
    return await f(arg, thunkAPI);
  } catch (e) {
    return rejectWithValue(e.response.data);
  }
};

export default catcher;
