import React from 'react';

import InputError from './InputError';

const RoleSelect = ({ meta, input }) => (
  <div>
    <select
      className="form__input form__select"
      onChange={value => input.onChange(value)}
      onBlur={() => input.onBlur(input.value)}
      {...input}
    >
      <option value="ROLE_USER">Utilisateur simple</option>
      <option value="ROLE_OFFICE_MANAGER">Responsable de bureau</option>
    </select>
    <InputError meta={meta} />
  </div>
);
export default RoleSelect;
