import { createSlice } from '@reduxjs/toolkit';

import {
  login,
  register,
  resetPassword,
  changePassword,
  refreshToken as refreshTokenThunk,
  getUser,
  googleLogin,
  googleLogout
} from '../thunks/authThunks';
import { setLoadingOff, setLoadingOn } from './helpers';

const initialState = {
  user: null,
  isLoggedIn: false,
  token: '',
  refreshToken: '',
  isLoading: false
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: state => {
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');

      for (const key in state) {
        state[key] = initialState[key];
      }
    },
    autoLogin: state => {
      const token = localStorage.getItem('token');
      const refreshToken = localStorage.getItem('refreshToken');
      const user = JSON.parse(localStorage.getItem('user'));
  
      if (token && user && refreshToken) {
        state.user = user;
        state.token = token;
        state.refreshToken = refreshToken;
        state.isLoggedIn = true;
      }
    },
  },
  extraReducers: {
    [refreshTokenThunk.fulfilled]: (state, { payload }) => {
      state.token = payload;
    },
    [login.fulfilled]: (state, { payload }) => {
      const { user, token, refreshToken } = payload;

      state.isLoading = false;
      state.user = user;
      state.token = token;
      state.refreshToken = refreshToken;
      state.isLoggedIn = true;
    },
    [login.pending]: setLoadingOn,
    [login.rejected]: setLoadingOff,
    [register.pending]: setLoadingOn,
    [register.fulfilled]: setLoadingOff,
    [register.rejected]: setLoadingOff,
    [resetPassword.pending]: setLoadingOn,
    [resetPassword.fulfilled]: setLoadingOff,
    [resetPassword.rejected]: setLoadingOff,
    [changePassword.pending]: setLoadingOn,
    [changePassword.fulfilled]: setLoadingOff,
    [changePassword.rejected]: setLoadingOff,
    [getUser.fulfilled]:(state,{ payload }) => {
      state.user = payload.data;
      localStorage.setItem('user',JSON.stringify(payload.data));
    },
    [googleLogin.pending]: setLoadingOn,
    [googleLogin.fulfilled]:setLoadingOff,
    [googleLogout.fulfilled]:(state,{payload}) => {
      state.user.googleAccessToken=null
      let user = JSON.parse(localStorage.getItem('user'));
      user.googleAccessToken  = null;
      user.googleRefreshToken = null;
      user.googleAuthorizedCalendarId = null;
      localStorage.setItem('user',JSON.stringify(user));
    }
  },
});

export const { autoLogin, logout } = authSlice.actions;

export default authSlice.reducer;
