import React, { useState } from 'react';
import { toastr } from 'react-redux-toastr'
import { GoogleOAuthProvider } from '@react-oauth/google';

import SideBar from '../SideBar/SideBar';
import Header from '../Header/Header';

import './Container.sass';

;
const responseError = error => {
  toastr.error('une erreur est survenue lorsque authentification google');
}
const Container = ({ children }) => {
  const [isSideBarVisible, setIsSideBarVisible] = useState(false);

  return (
    <div className="container">
      <SideBar
        isSideBarVisible={isSideBarVisible}
        setIsSideBarVisible={setIsSideBarVisible}
      />
      <div className="container__content bg-grey">
      <GoogleOAuthProvider 
      clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}
      onScriptLoadError={responseError}>
        <Header
          setIsSideBarVisible={setIsSideBarVisible}
          isSideBarVisible={isSideBarVisible}
        />
        </GoogleOAuthProvider>
        <div className="container__content-wrapper">{children}</div>
      </div>
    </div>
  );
};
export default Container;
