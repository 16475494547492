import { createAsyncThunk } from '@reduxjs/toolkit';

import catcher from './catcher';
import server from '../../services/server';
import { getAuth } from './helpers';

export const getBookingsByOfficeId = createAsyncThunk(
  'bookings/getBookingsByOfficeId',
  catcher(async ({ officeId, startDate, endDate }, { getState }) => {
    return server.get(
      `bookings?room.office.id=${officeId}&startDate[after]=${startDate}&endDate[before]=${endDate}&exists[cancelledBy]=false`,
      getAuth(getState)
    );
  })
);

export const getBookingsAgenda = createAsyncThunk(
  'bookings/getBookingsAgenda',
  catcher(async ({ officeId, startDate, endDate }, { getState }) => {
    return server.get(`/user/events?officeId=${officeId}&startDate=${startDate}&endDate=${endDate}`, getAuth(getState));
  })
);

export const book = createAsyncThunk(
  'bookings/book',
  catcher(async ({ formValues }, { getState }) => {
    return server.post('/bookings', formValues, getAuth(getState));
  })
);

export const cancelBooking = createAsyncThunk(
  'booking/cancel',
  catcher(async ({ bookingId }, { getState }) => {
    return server.get(`/bookings/${bookingId}/cancel`, getAuth(getState));
  })
);

export const removeBooking = createAsyncThunk(
  'booking/remove',
  catcher(async ({ bookingId }, { getState }) => {
    return server.delete(`/bookings/${bookingId}`, getAuth(getState));
  })
);

export const closeBooking = createAsyncThunk(
  'booking/close',
  catcher(async ({ bookingId }, { getState }) => {
    return server.get(`/bookings/${bookingId}/close`, getAuth(getState));
  })
);

export const updateBooking = createAsyncThunk(
  'booking/update',
  catcher(async ({ formValues }, { getState }) => {
    return server.put(
      `/bookings/${formValues.bookingId}`,
      formValues,
      getAuth(getState)
    );
  })
);
