import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { reduxForm, Field } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { toastr } from 'react-redux-toastr';

import Spinner from '../Spinner/Spinner';
import Input from '../Form/Input';
import RememberCheckBox from '../Form/RememberMeCheckBox';
import CosaVostraMeta from '../Ui/CosaVostraMeta';
import { login } from '../../redux/thunks/authThunks';
import { email, required } from '../../helpers/validation/fieldvalidation';


const Login = ({ handleSubmit, initialize }) => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector(state => state.auth);

  useEffect(() => {
    initialize({
      rememberMe: true,
    });
  }, [initialize]);

  const handleFormSubmit = formValues => {
    dispatch(login(formValues))
      .then(unwrapResult)
      .catch(e => toastr.error('Accès interdit', e.detail || e.message));
  };

  return (
    <div className="form">
      <div className="form__content-wrapper">
        <CosaVostraMeta />
        <form className="form__form" onSubmit={handleSubmit(handleFormSubmit)}>
          <div className="form__title">Se connecter</div>
          <Field
            name="username"
            type="email"
            placeholder="Email"
            label="Email"
            component={Input}
            validate={[required, email]}
          />
          <Field
            name="password"
            type="password"
            placeholder="Mot de passe"
            label="Mot de passe"
            component={Input}
            validate={[required]}
          />
          <div className="form__forgot">
            <Link className="form__blue-color form__link" to="/password/reset">
              Mot de passe oublié?
            </Link>
          </div>
          <div className="form__remember-me-wrapper">
            <Field name="rememberMe" component={RememberCheckBox} />
            <label htmlFor="remember">Se souvenir de moi</label>
          </div>
          <div className="form__button-wrapper">
            <button
              disabled={isLoading}
              className={`form__button ${isLoading ? 'form__button--disabled' : ''
                }`}
            >
              Se connecter
            </button>
            <Spinner loading={isLoading} overlay />
          </div>

          <div className="form__register">
            Vous n&apos;avez pas de compte ?{' '}
            <Link to="/register" className="form__blue-color form__link">
              Inscription
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default reduxForm({ form: 'loginForm' })(Login);
