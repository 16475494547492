import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';


import CustomLink from './CustomLink';
import classNames from 'classnames';
import { logout } from '../../redux/slices/authSlice';
import DisplayFor from '../DisplayFor';
import { isGranted, roles } from '../../helpers/roles';
import Offices from "./Offices";


import './SideBar.sass';


const SideBar = ({ isSideBarVisible, setIsSideBarVisible }) => {
  const ref = useRef();
  const dispatch = useDispatch();
  const location = useLocation();
  const authUser = useSelector(state => state.auth.user);
  const [officeId, setOfficeId] = useState(location.state?.officeId ?? authUser.office.id);
  const [mdSideBarHide, setMdSideBarHide] = useState(false);
  const sideBarClassNames = classNames('side-bar', {
    'side-bar--visible': isSideBarVisible,
    'md-sidebar--hide': mdSideBarHide
  });
  const setSideBarHeight = useCallback(() => {
    document.body.style.setProperty(
      '--side-bar-width',
      `${ref.current.clientWidth}px`
    );
  }, []);

  useEffect(() => {
    window.addEventListener('load', setSideBarHeight);

    return () => window.removeEventListener('load', setSideBarHeight);
  }, [setSideBarHeight]);
  useEffect(setSideBarHeight);

  return (
    <aside className={sideBarClassNames} ref={ref}>

      <div className="side-bar__header">

        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="side-bar__cosa-logo"
        >
          <path
            d="M5.02159 20.0893H8.93646V24H5.02159V20.0893ZM15.0648 20.0893H18.9796V24H15.0648V20.0893ZM0 15.068H3.91487V18.9773H0V15.068ZM5.02159 15.068H8.93646V18.9773H5.02159V15.068ZM10.0432 15.068H13.9581V18.9773H10.0432V15.068ZM15.0648 15.068H18.9796V18.9773H15.0648V15.068ZM20.0864 15.068H24.0012V18.9773H20.0864V15.068ZM5.02159 10.0453H8.93646V13.9547H5.02159V10.0453ZM15.0648 10.0453H18.9796V13.9547H15.0648V10.0453ZM0 5.02267H3.91487V8.932H0V5.02267ZM5.02159 5.02267H8.93646V8.932H5.02159V5.02267ZM10.0432 5.02267H13.9581V8.932H10.0432V5.02267ZM15.0648 5.02267H18.9796V8.932H15.0648V5.02267ZM20.0864 5.02267H24.0012V8.932H20.0864V5.02267ZM5.02159 0H8.93646V3.90933H5.02159V0ZM18.9796 0V3.90933H15.0648V0H18.9796Z"
            fill="white"
          />
        </svg>
        <a href="/Calendar" className="side-bar__reservercosa">BookMyRoom</a>

        <i
          className="fa-solid fa-angles-left side-bar__close"
          onClickCapture={() => {
            setMdSideBarHide(!mdSideBarHide);
            setIsSideBarVisible(false);
          }}

        />
        <i
          className="fa-solid fa-angles-right side-bar__open"
          onClickCapture={() => { setMdSideBarHide(!mdSideBarHide); }}
        />
      </div>

      <ul
        className="side-bar__links"
        onClick={() => setIsSideBarVisible(false)}
      >
        <li>
          <CustomLink to="/Calendar" state={{ officeId: officeId }}>
            <i className="fa-solid fa-calendar"></i> <span>Calendrier</span>
          </CustomLink>
        </li>
        <Offices officeId={officeId} setOfficeId={setOfficeId} />
        <DisplayFor isGranted={isGranted(roles.OFFICE_MANAGER, authUser.roles)}>
          <li>
            <CustomLink to="/users">
              <i className="fa-solid fa-user-group"></i> <span>Tous les utilisateurs</span>
            </CustomLink>
          </li>
        </DisplayFor>
        <DisplayFor isGranted={isGranted(roles.ADMIN, authUser.roles)}>
          <li>
            <CustomLink to="/users/manage">
              <i className="fa-solid fa-users-gear"></i> <span>Gérer tous les
                utilisateurs</span>
            </CustomLink>
          </li>
        </DisplayFor>
        <DisplayFor isGranted={isGranted(roles.OFFICE_MANAGER, authUser.roles)}>
          <li>
            <CustomLink to="/rooms">
              <i className="fa-solid fa-building-columns"></i> <span>Gestion des salles</span>
            </CustomLink>
          </li>
        </DisplayFor>
      </ul>
      <ul className="side-bar__links side-bar__links--bottom">
      <li>
            <CustomLink to="/my-account">
              <i className="fa-solid fa-user"></i> <span>Mon compte</span>
            </CustomLink>
          </li>
        <li onClick={() => dispatch(logout())} className="custom-link">
          <i className="fa-solid fa-arrow-right-from-bracket"></i>  <span>Se
            déconnecter</span>
        </li>
      </ul>
    </aside>
  );
};

export default SideBar;
