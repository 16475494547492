import React from 'react';
import { Link } from 'react-router-dom';

import './CosaVostraMeta.sass';

const CosaVostraMeta = () => (
  <div className="cosavostra-meta">
    <Link to="/" className="cosavostra-meta__app-name">
      <svg
        width="70"
        height="70"
        viewBox="0 0 70 70"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="cosavostra-meta__logo"
      >
        <path
          d="M14.6456 58.5926H26.0633V69.9981H14.6456V58.5926ZM43.9367 58.5926H55.3544V69.9981H43.9367V58.5926ZM0 43.9478H11.4178V55.3494H0V43.9478ZM14.6456 43.9478H26.0633V55.3494H14.6456V43.9478ZM29.2911 43.9478H40.7089V55.3494H29.2911V43.9478ZM43.9367 43.9478H55.3544V55.3494H43.9367V43.9478ZM58.5822 43.9478H70V55.3494H58.5822V43.9478ZM14.6456 29.2991H26.0633V40.7007H14.6456V29.2991ZM43.9367 29.2991H55.3544V40.7007H43.9367V29.2991ZM0 14.6504H11.4178V26.052H0V14.6504ZM14.6456 14.6504H26.0633V26.052H14.6456V14.6504ZM29.2911 14.6504H40.7089V26.052H29.2911V14.6504ZM43.9367 14.6504H55.3544V26.052H43.9367V14.6504ZM58.5822 14.6504H70V26.052H58.5822V14.6504ZM14.6456 0.00170898H26.0633V11.4033H14.6456V0.00170898ZM55.3544 0.00170898V11.4033H43.9367V0.00170898H55.3544Z"
          fill="black"
        />
      </svg>
      <div>
        <h1>BookMyRoom</h1>
        <div className="cosavostra-meta__made-with-love">
          Made with ❤️ by CosaVostra
        </div>
      </div>
    </Link>
    <div className="cosavostra-meta__description">
        BookMyRoom est l'outil de réservation des salles de réunion des différents bureaux de CosaVostra.
    </div>
  </div>
);

export default CosaVostraMeta;
