import React, { useEffect, useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';

import ReactModal from 'react-modal';
import Input from '../Form/Input';
import OfficeSelect from '../Form/OfficeSelect';
import { areFilesExtensionValid } from '../../helpers/validation/room/validate.js';
import { isNumber, required } from '../../helpers/validation/fieldvalidation';
import { addRoom, removeImage, updateRoom, uploadImages } from '../../redux/thunks/roomsThunks';
import { removeRoomImageById } from '../../redux/slices/roomsSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { SERVER_URL } from '../../services/server';
import { getCalendarList } from '../../redux/thunks/usersThunks';
import CustomSelect from '../Form/CustomSelect';

import './Modal.sass';


const RoomModal = ({ ...props }) => {
  ReactModal.setAppElement('#list-modal');
  const dispatch = useDispatch();
  const { handleSubmit, submitting, pristine, initialize, roomId } = props
  const authUser = useSelector(state => state.auth.user);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [fileError, setFileError] = useState('');
  const { refreshData, handleCloseModal } = props;
  const room = useSelector(state => state.rooms.all).find(
    b => b.id === roomId
  );
  const calendarList = useSelector(state => state.users.calendarList);


  useEffect(() => {
    (room) ? initialize({
      'office': room.office.id,
      'title': room.title,
      'description': room.description,
      'size': room.size,
      'calendarId': room.calendarId
    }) :
      initialize({ 'office': authUser.office.id, 'title': '', 'description': '', 'calendarId': null })
  }, [room])

  useEffect(() => {
    dispatch(getCalendarList())
  }, [])


  const onFormSubmit = data => {
    const formData = new FormData();
    if (selectedFiles) {
      [...selectedFiles].forEach(function (item) {
        formData.append('images[]', item);
      });

    }
    dispatch(room ? updateRoom({ data: data, roomId: roomId }) : addRoom({ data }))
      .then(unwrapResult)
      .then(({ data }) => {

        if (selectedFiles) {
          dispatch(uploadImages({ roomId: data.id, formData }));
        }

        handleCloseModal();
        setSelectedFiles([]);
        if (refreshData) {
          refreshData();
        }

        toastr.success(room ? 'Salle a été mis a jour' : 'une nouvelle Salle a été ajoutée');
      }

      )
      .catch(e => toastr.error(e.message));

    setFileError('');
  }

  return (
    <ReactModal
      isOpen={props.openModal}
      className="modal RoomModal"
      onRequestClose={handleCloseModal}
      shouldCloseOnOverlayClick={true}
      preventScroll={true}
    >
      <i className='fa fa-close close' onClick={handleCloseModal}></i>
      <form className='modal-form' onSubmit={handleSubmit(onFormSubmit)}>
        <h1 className="modal-form__headline">Salle</h1>
        <Field
          name="title"
          type="text"
          placeholder="Salle"
          label="Nom de la salle "
          component={Input}
          validate={[required]}
        />
        <label htmlFor="description" className="form__label">Description de la salle</label>
        <Field
          name="description"
          placeholder="Description"
          component="textarea"
          className='form__input'
        />
        <Field
          name="size"
          type="number"
          placeholder="Nombre de personnes"
          label="Nombre de personnes"
          component={Input}
          parse={(value, name) => parseInt(value)}
          validate={[required, isNumber]}
        />
        <Field
          name="office"
          label="Sélectionner un bureau"
          component={OfficeSelect}
          validate={[required]}
        />

        <Field
          disabled={room?.calendarId}
          name="calendarId"
          label="Google Agenda"
          component={CustomSelect}
          optionLabelKey="summary"
          optionValueKey="id"
          options={calendarList}
        />

        <label htmlFor="description" className="form__label">  Télécharger les images de la salle</label>
        <div className='form-row--file'>
          <input
            name='images'
            className='custom-file-input'
            type="file"
            accept=".jpg,.jpeg,.png"
            onChange={(e) => {
              if (areFilesExtensionValid(e.target.files)) {
                setFileError('');
                setSelectedFiles(e.target.files);

              } else {
                setFileError('Veuillez entrer un format de fichier valide [.png, jpg ou .jpeg]');
              }

            }}
            multiple={true}
          />
          <label className="custom-file-label">Importer les photos</label>
        </div>
        <div className="form__input-error">{fileError}</div>

        <div className='images'>
          {room?.images.map(image =>
            <div className='images__wrapper' key={image.id}>
              <img
                src={`${SERVER_URL}${image.contentUrl}`}
                className="images__item" alt=""
              />
              <i
                className="fa-regular fa-trash-can"
                onClick={(e) => dispatch(removeImage({ mediaId: image.id }))
                  .then(() => dispatch(removeRoomImageById({ roomId: room.id, imageId: image.id })))
                }
              />
            </div>
          )}

        </div>


        <div className="form__button-wrapper">
          <button className={`form__button ${(pristine || submitting) && (!room) ? 'form__button--disabled' : ''
            }`} disabled={(pristine || submitting) && (!room)}
          >
            Valider
          </button>
        </div>
      </form>

    </ReactModal>
  )
}



export default reduxForm({ form: 'modalForm' })(RoomModal);