import React from 'react';
import { connect } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

import HistoryContext from '../../contexts/HistoryContext';

const PrivateRoute = ({ children, isLoggedIn }) => {
  const { state } = useLocation();

  return isLoggedIn ? (
    <HistoryContext.Provider value={state}>{children}</HistoryContext.Provider>
  ) : (
    <Navigate to="/login" />
  );
};

const mapStateToProps = state => ({ isLoggedIn: state.auth.isLoggedIn });

export default connect(mapStateToProps)(PrivateRoute);
