import React, { useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { reduxForm, Field } from 'redux-form';
import { toastr } from 'react-redux-toastr';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';

import Input from '../Form/Input';
import OfficeSelect from '../Form/OfficeSelect';
import Spinner from '../Spinner/Spinner';
import CosaVostraMeta from '../Ui/CosaVostraMeta';
import { register } from '../../redux/thunks/authThunks';
import PasswordsMatch from './PasswordsMatch';
import { email, minLength5, passwordMatch, required } from '../../helpers/validation/fieldvalidation';

const Register = ({ handleSubmit, initialize }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoading } = useSelector(state => state.auth);
  const offices = useSelector(state => state.offices.list);

  useEffect(() => {
    if (offices.length) {
      initialize({
        office: offices[0]['@id'],
      });
    }
  }, [offices.length, initialize, offices]);

  const handleFormSubmit = formValues => {
    dispatch(register(formValues))
      .then(unwrapResult)
      .then(() => {
        toastr.success(
          'Horrai!',
          'Inscription réussie. connectez-vous maintenant.'
        );

        navigate('/login');
      })
      .catch(e => {
        toastr.error('Oups!', e.violations[0].message);
      });
  };

  return (
    <div className="form">
      <div className="form__content-wrapper">
        <CosaVostraMeta />
        <form className="form__form" onSubmit={handleSubmit(handleFormSubmit)}>
          <div className="form__title">Inscription</div>
          <div className="form__two-columns">
            <Field
              name="firstName"
              type="text"
              placeholder="Prénom"
              label="Prénom"
              component={Input}
              validate={[required]}
            />
            <Field
              name="lastName"
              type="text"
              placeholder="Nom"
              label="Nom"
              component={Input}
              validate={[required]}
            />
          </div>
          <Field
            name="username"
            type="email"
            placeholder="Email"
            label="Email"
            component={Input}
            validate={[required, email]}
          />
          <Field
            name="job"
            type="text"
            placeholder="Poste"
            label="Poste"
            component={Input}
            validate={[required]}
          />
          <Field
            name="company"
            type="text"
            placeholder="Entreprise"
            label="Entreprise"
            component={Input}
            validate={[required]}
          />
          <Field name="office"
            component={OfficeSelect}
            validate={required}
          />
          <div className="form__two-columns">
            <Field
              name="password"
              type="password"
              placeholder="Mot de passe"
              label="Mot de passe"
              component={Input}
              validate={[required, minLength5]}
            />
            <Field
              name="passwordConfirm"
              type="password"
              placeholder="Confirmation"
              label="Confirmation"
              component={Input}
              validate={[required, passwordMatch, minLength5]}
            />
          </div>
          <PasswordsMatch formId="registrationForm" />
          <div className="form__button-wrapper">
            <button
              disabled={isLoading}
              className={`form__button ${isLoading ? 'form__button--disabled' : ''
                }`}
            >
              S&apos;inscrire
            </button>
            <Spinner loading={isLoading} overlay />
          </div>
          <Link className="form__blue-color form__link" to="/login">
            J&apos;ai déjà un compte
          </Link>
        </form>
      </div>
    </div>
  );
};

export default reduxForm({ form: 'registrationForm' })(Register);
