export const capitalizeWords = (str) => {
    str = str.replaceAll(/[\d_]+/g, '');
    const splittedStr = str.split(/[\W_]+/g);
    let capitalizedStr = '';
    splittedStr.forEach((s, index) => {
        if (s) {
            capitalizedStr += `${s[0].toUpperCase()}${s.slice(1)}${index === splittedStr.length - 1 ? '' : ' '}`;
        }
    })

    return capitalizedStr;
}


export const getPageNumber = iri => {
    const queryParams = new URLSearchParams(iri);
    const page = queryParams.get('page');

    return page ? parseInt(page) : 1;
};