import React from 'react';

const DisplayFor = ({ children, isGranted }) => {
  if (isGranted) {
    return <>{children}</>;
  }

  return null;
};

export default DisplayFor;
