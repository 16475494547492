import sanitizeHtml from 'sanitize-html';


const sanitize = (dirty, options) => ({
    __html: sanitizeHtml(
        dirty,
        options
    )
});

export const SanitizeHTML = ({ html, options, ...props }) => (
    <div className={props.className} dangerouslySetInnerHTML={sanitize(html, options)} />
);