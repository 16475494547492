import classNames from 'classnames';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useNavigate } from "react-router-dom";

import {getRoomsByOfficeId} from '../../redux/thunks/roomsThunks';
import Room from './Room';

import './Office.sass';

const Office = ({office, isVisible, setOpenedOfficeId, setOfficeId, officeId}) => {
    const dispatch = useDispatch();
    const {list: rooms} = useSelector(state => state.rooms);
    const navigate = useNavigate();
    useEffect(() => {
        if (isVisible) {
            dispatch(getRoomsByOfficeId({officeId: office.id}));
        }
    }, [dispatch, isVisible]);

    const className = classNames('office', {
        'office--visible': isVisible,
    });

    return (
        <li className={className}>
            <div className="office__title" onClick={
                () => { setOpenedOfficeId(office.id);
                setOfficeId(office.id); navigate('/Calendar', { state: { officeId: office.id } });
            }}
            >
                <div className="office__wrapper">
                    <i className="fa-solid fa-building"></i>
                    <div>{office.location}</div>
                </div>
                <i className="fa-solid fa-angle-down"></i>
            </div>
            <div className="office__rooms-container">
                {rooms?.map(room =>
                    ((office.id === officeId) && 
                    <Room key={room.id} room={room}/>)
                )}
            </div>
        </li>
    );
};

export default Office;
