import React from 'react';
import ReactPaginate from 'react-paginate';

import './Paginate.sass';

const Paginate = ({ pagination, handlePageClick }) => {
  if (!pagination) {
    return null;
  }
 
  const getNumberFromString = string => {
    return (string) ? parseInt(string.substr(string.indexOf('page=')).match(/(\d+)/)[0]) : 0 ;
  };

  return (
    <ReactPaginate
      breakLabel="..."
      nextLabel=">"
      onPageChange={handlePageClick}
      forcePage={getNumberFromString(pagination['@id']) - 1}
      pageCount={getNumberFromString(pagination['hydra:last'])}
      previousLabel="<"
      renderOnZeroPageCount={null}
      containerClassName="pagination-buttons"
      activeClassName="pagination-active"
      pageRangeDisplayed={window.innerWidth < 400 ? 1 : 3}
      marginPagesDisplayed={1}
    />
  );
};

export default Paginate;
