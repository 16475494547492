import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from './components/App/App';
import store from './redux/store';
import { autoLogin } from './redux/slices/authSlice';
import { refreshToken } from './redux/thunks/authThunks';

store.dispatch(autoLogin());
store.dispatch(refreshToken());

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
