import { createAsyncThunk } from '@reduxjs/toolkit';

import server from '../../services/server';
import catcher from './catcher';
import { getAuth } from './helpers';

export const getOffices = createAsyncThunk(
  'offices/getOffices',
  catcher(async ( _arg, thunkAPI) => {
    return server.get(`/offices`,getAuth(thunkAPI.getState));
  })
);

export const getOfficeById = createAsyncThunk(
  'offices/getOffice',
  catcher(async ( { officeId }, { getState }) => {
    return server.get(`/offices/${officeId}`,getAuth(getState));
  })
);
