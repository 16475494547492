import React from 'react';

import InputError from './InputError';


const CustomSelect = ({ meta, input,label,optionLabelKey,optionValueKey,options,...rest }) => { 

  const renderOptions = options.map(option => (
    <option value={option[optionValueKey]} key={option.id}>
      {option[optionLabelKey]}
    </option>
  ));

  return (
    <div>
    <label className="form__label">{label}</label>
      <select
        value={input.value}
        className="form__input form__select"
        onChange={e => input.onChange(e.target.value)}
        onBlur={() => input.onBlur(input.value)}
        {...rest}
      >
      <option value=''></option>
        {renderOptions}
      </select>
      <InputError meta={meta} />
    </div>
  );
};

export default CustomSelect;
