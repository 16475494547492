import { FormControl, MenuItem, Select } from "@material-ui/core";

const OfficeSelectFilter = ({ columnDef, onFilterChanged,offices }) => {
    
    return (
    <FormControl fullWidth>
      <Select
        variant="outlined"
        defaultValue={0}
        onChange={(e) => {
          onFilterChanged(columnDef.tableData.id, e.target.value);
        }}
      >
        <MenuItem value={0}>Tous</MenuItem>
        {offices.map((office) =>
          <MenuItem key={office.id} value={office.id}>{office.location}</MenuItem>
        )}
      </Select>
    </FormControl>
    );
  }


export default OfficeSelectFilter;