import React from 'react';
import { useDispatch } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { unwrapResult } from '@reduxjs/toolkit';
import classnames from 'classnames';

import { updateApproval } from '../../redux/thunks/usersThunks';

import './Toggle.sass';

const ToggleApproval = ({ isApproved, userId }) => {
  const dispatch = useDispatch();

  const onToggleApprovalClick = () => {
    dispatch(updateApproval({ userId, isApproved: !isApproved }))
      .then(unwrapResult)
      .then(() => toastr.success('Cool!', 'Statut mis à jour'))
      .catch(({ message }) => {
        toastr.error(
          'Oups!',
          message || 'Une erreur s\'est produite. Veuillez réessayer.'
        );
      });
  };

  const className = classnames('toggle-approval', {
    'toggle-approval--off': !isApproved,
  });

  return <div className={className} onClick={onToggleApprovalClick}></div>;
};

export default ToggleApproval;
