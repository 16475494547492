import { useGoogleLogin } from '@react-oauth/google';
import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getStartHour } from '../../helpers/dates';


import BookModal from '../Modal/BookModal';
import { getUser, googleLogin, googleLogout } from '../../redux/thunks/authThunks';
import { isGranted, roles } from '../../helpers/roles';


import './Header.sass';


const Header = ({ setIsSideBarVisible, isSideBarVisible }) => {
  const ref = useRef();
  const dispatch = useDispatch();
  const { googleAccessToken, ...authUser } = useSelector(state => state.auth.user);
  const [open, setOpen] = useState(false);
  const [startHour, setStartHour] = useState(getStartHour());
  const [endHour, setEndHour] = useState(getStartHour(15));
  const [bookDate, setBookDate] = useState(new Date());

  useEffect(() => {
    document.body.style.setProperty(
      '--header-height',
      `${ref.current.clientHeight}px`
    );
  }, []);

  const handleOnClick = () => {
    setOpen(!open)
  }
  const handleCloseModal = () => {
    setOpen(!open)
  }

  const login = useGoogleLogin({
    onSuccess: codeResponse => {

      dispatch(googleLogin({ code: codeResponse.code }))
      .then(() =>  dispatch(getUser()))

    },
    flow: 'auth-code',
    scope: ['https://www.googleapis.com/auth/calendar']
  });

  const logout = () => {
    dispatch(googleLogout());
  }

  return (
    <header className="header" ref={ref}>
      <button className="header__book-button" onClick={handleOnClick}>
      <i className="fa fa-plus fa-1x" aria-hidden="true"></i>Réserver
      </button>
      <div className="header__sync-google">
      {isGranted(roles.SUPER_ADMIN, authUser.roles) && (
        <>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M23.7449 12.27C23.7449 11.48 23.6749 10.73 23.5549 10H12.2549V14.51H18.7249C18.4349 15.99 17.5849 17.24 16.3249 18.09V21.09H20.1849C22.4449 19 23.7449 15.92 23.7449 12.27Z"
            fill="#4285F4"
          />
          <path
            d="M12.2549 24C15.4949 24 18.2049 22.92 20.1849 21.09L16.3249 18.09C15.2449 18.81 13.8749 19.25 12.2549 19.25C9.12492 19.25 6.47492 17.14 5.52492 14.29H1.54492V17.38C3.51492 21.3 7.56492 24 12.2549 24Z"
            fill="#34A853"
          />
          <path
            d="M5.52488 14.2901C5.27488 13.5701 5.14488 12.8001 5.14488 12.0001C5.14488 11.2001 5.28488 10.4301 5.52488 9.71012V6.62012H1.54488C0.724882 8.24012 0.254883 10.0601 0.254883 12.0001C0.254883 13.9401 0.724882 15.7601 1.54488 17.3801L5.52488 14.2901Z"
            fill="#FBBC05"
          />
          <path
            d="M12.2549 4.75C14.0249 4.75 15.6049 5.36 16.8549 6.55L20.2749 3.13C18.2049 1.19 15.4949 0 12.2549 0C7.56492 0 3.51492 2.7 1.54492 6.62L5.52492 9.71C6.47492 6.86 9.12492 4.75 12.2549 4.75Z"
            fill="#EA4335"
          />
        </svg>
        
        {!googleAccessToken && <button className='header__sync-button' onClick={() => login()}>Synchroniser mon agenda</button>}
        {googleAccessToken && <button className='header__sync-button' onClick={() => logout()}>Deconnexion</button>}
        </>
      )}
      </div>
      {!isSideBarVisible && (
        <i
          className="fa-solid fa-bars header__bars"
          onClick={() => setIsSideBarVisible(!isSideBarVisible)}
        />
      )}
      <BookModal
        openModal={open}
        officeId={authUser.office.id}
        handleCloseModal={handleCloseModal}
        bookDate={bookDate}
        setBookDate={setBookDate}
        startHour={startHour}
        setStartHour={setStartHour}
        endHour={endHour}
        setEndHour={setEndHour}
      />
    </header>
  );
};

export default Header;
