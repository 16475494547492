import { createSlice } from '@reduxjs/toolkit';

import { getOffices, getOfficeById } from '../thunks/officesThunks';

const initialState = { 
  list: [],
  office: {}
 };

export const officesSlice = createSlice({
  name: 'offices',
  initialState,
  extraReducers: {
    [getOffices.fulfilled]: (state, { payload }) => {
      state.list = payload.data['hydra:member'];
    },
    [getOfficeById.fulfilled] : (state, { payload }) => {
      state.office = payload.data
    },
  },
});

export default officesSlice.reducer;
