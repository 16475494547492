import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import DateTimePicker from 'react-datetime-picker';
import { unwrapResult } from '@reduxjs/toolkit';
import { toastr } from 'react-redux-toastr';
import moment from 'moment';
import 'moment/locale/fr';

import TimePicker from '../Form/TimePicker';
import Input from '../Form/Input';
import { combineDates, toLocal } from '../../helpers/dates';
import { required } from '../../helpers/validation/fieldvalidation';
import { getOfficeById } from '../../redux/thunks/officesThunks';
import { book, updateBooking } from '../../redux/thunks/bookingsThunks';
import OfficeRoomsSelect from '../Form/OfficeRoomsSelect';


import './Modal.sass';
import 'react-datepicker/dist/react-datepicker.css';


const BookModal = ({ ...props }) => {

    ReactModal.setAppElement('#list-modal')
    const dispatch = useDispatch();
    const office = useSelector(state => state.offices.office);
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
    const [bookDate, setBookDate] = useState(new Date());
    const [room, setRoom] = useState(null);
    const [startHour, setStartHour] = useState(bookDate.getTime());
    const [endHour, setEndHour] = useState(bookDate.getTime() + 900000);
    const { handleSubmit,
        initialize,
        openModal,
        error,
        submitting,
        officeId,
        bookId,
        event
    } = props

    const booking = useSelector(state => state.bookings.list).find(
        b => b.id.toString() === bookId
    );


    const setBookingDetails = (room, startDate, endDate, book = null) => {
        setBookDate(startDate);
        setStartHour(startDate.getTime());
        setEndHour(endDate.getTime());
        setRoom(room);
        initialize({ "title": booking?.title || '', "room": room.id });
    }

    useEffect(() => {
        if (booking) {
            setBookingDetails(booking.room, toLocal(booking.startDate), toLocal(booking.endDate));
        } else if (event) {
            const room = event.resource._resource;
            setBookingDetails(room, event.start, event.end);
        }

    }, [openModal])

    useEffect(() => {
        dispatch(
            getOfficeById({
                officeId: officeId,
            })
        )

    }, [dispatch, officeId, room]);



    const setStartHourAndAdjustEndHour = (startValue) => {
        setStartHour(startValue);
        if (new Date(endHour).getTime() <= new Date(startValue).getTime()) {
            setEndHour(startValue.getTime() + 900000);
        }
    };

    const onFormSubmit = formValues => {

        const startDate = combineDates(bookDate, startHour);

        if (startDate.getTime() < new Date().getTime()) {
            throw new SubmissionError({
                _error: 'Merci de sélectionner une date valide.'
            })
        }
        const data = {
            formValues: {
                ...formValues,
                bookingId: booking?.id,
                startDate: startDate,
                endDate: combineDates(bookDate, endHour)
            },
        };

        return dispatch(booking ? updateBooking(data) : book(data))
            .then(unwrapResult)
            .then((res) => {

              if (booking) {
                  toastr.success('la réservation a été mise à jour');
              } else {
                  initialize({'title': ''});
                  res?.data?.googleCalendarId ?
                      toastr.success('une nouvelle réservation a été ajoutée') :
                      toastr.warning('Erreur lors de l\'envoi de la réservation au calendrier Google.')
                  ;
              }

                props.handleCloseModal();

            })
            .catch(e => toastr.error('Oups!', e.message ?? 'Une erreur s\'est produite. Veuillez réessayer.'));


    };

    return (
        <ReactModal
            isOpen={props.openModal}
            className="modal"
            onRequestClose={props.handleCloseModal}
            shouldCloseOnOverlayClick={true}
        >
            <form className="book" onSubmit={handleSubmit(onFormSubmit)}>
                <h1 className="book__headline">Réserver une salle</h1>
                {(!booking &&
                    <OfficeRoomsSelect
                        name="office"
                        officeLabel="Sélectionner un bureau"
                        defaultOffice={office}
                        defaultRoomId={room?.id}
                    />)}

                <div>
                    <div className='date__labels-wrapper'>
                        <label className="form__label date__labels-date">Date</label>
                        <label className="form__label">Heure</label>
                    </div>
                    <div className="book__date-time-wrapper">
                        <div
                            className="book__date"
                            onClick={() => setIsDatePickerOpen(!isDatePickerOpen)}
                        >
                            <i className="fa-solid fa-clock book__clock-icon" />
                            {moment(bookDate).format('dddd, MMMM D')}
                            <i className='fa-solid fa-chevron-down'></i>
                        </div>


                        <TimePicker
                            name='start'
                            time={startHour}
                            setTime={setStartHourAndAdjustEndHour}
                        />
                        <div className="book__time-separator">-</div>
                        <TimePicker
                            name='end'
                            time={endHour}
                            setTime={setEndHour}
                            minTime={new Date(startHour).getTime() + 90000}
                        />

                    </div>
                    {error && <div className="form__input-error d-block mt-1"> {error}</div>}

                    <DateTimePicker
                        locale="fr"
                        onChange={setBookDate}
                        value={bookDate}
                        minDate={moment().toDate()}
                        isCalendarOpen={isDatePickerOpen}
                    />

                </div>
                <Field
                    name="title"
                    type="text"
                    placeholder="Nom de la réunion"
                    label="Nom de la réunion"
                    component={Input}
                    validate={[required]}
                />


                <div className="form__button-wrapper">
                    <button className={`form__button ${(submitting) ? 'form__button--disabled' : ''
                        }`} disabled={(submitting)}
                    >
                        Valider
                    </button>

                </div>
            </form>

        </ReactModal>
    );
};
export default reduxForm({ form: 'modalForm', enableReinitialize: false })(BookModal);