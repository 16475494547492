import React, { useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { toastr } from 'react-redux-toastr';
import { unwrapResult } from '@reduxjs/toolkit';
import classNames from 'classnames';

import Spinner from '../Spinner/Spinner';
import Input from '../Form/Input';
import Container from '../Container/Container';
import {
  getUserById,
  updateUser,
  updateUserRole,
} from '../../redux/thunks/usersThunks';
import RoleSelect from '../Form/RoleSelect';
import OfficeSelect from '../Form/OfficeSelect';
import ToggleApproval from '../Toggles/ToggleApproval';
import DisplayFor from '../DisplayFor';
import { getMainRole, isGranted, roles } from '../../helpers/roles';
import { required } from '../../helpers/validation/fieldvalidation';

const UserDetails = ({ handleSubmit, initialize }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authUser = useSelector(state => state.auth.user);
  const { data: users, isLoading } = useSelector(state => state.users);
  const isEditPage = useLocation().pathname.includes('edit');

  const user = users.find(u => u.id === parseInt(id));

  useEffect(() => {
    if (!user) {
      dispatch(getUserById({ id }))
        .then(unwrapResult)
        .catch(() => {
          navigate('/users');
        });
    }
  }, [user, id, dispatch, navigate]);

  useEffect(() => {
    if (user) {
      const {
        firstName,
        lastName,
        username,
        job,
        company,
        office,
        isApproved,
      } = user;

      let role = 'Simple utilisateur';

      if (isGranted(roles.ADMIN, user.roles)) {
        role = 'Admin';
      } else if (isGranted(roles.OFFICE_MANAGER, user.roles)) {
        role = 'Responsable de bureau';
      }

      if (isEditPage) {
        role = 'ROLE_USER';

        if (isGranted(roles.ADMIN, user.roles)) {
          role = 'ROLE_ADMIN';
        } else if (isGranted(roles.OFFICE_MANAGER, user.roles)) {
          role = 'ROLE_OFFICE_MANAGER';
        }
      }

      initialize({
        firstName,
        lastName,
        username,
        job,
        company,
        role,
        office: isEditPage ? office['id'] : office.location,
        status: isApproved
      });
    }
  }, [user, initialize, isEditPage]);

  if (!user) {
    return (
      <Container>
        <Spinner loading />
      </Container>
    );
  }

  const handleFormSubmit = formValues => {
    if (!isEditPage) {
      return;
    }
    const { role } = formValues;

    if (
      isGranted(roles.ADMIN, authUser.roles) &&
      role !== getMainRole(user.roles)
    ) {
      dispatch(updateUserRole({ role, id }))
        .then(unwrapResult)
        .catch(e =>
          toastr.error(
            'Sorry!',
            e.message ?? 'Une erreur s\'est produite. Veuillez réessayer.'
          )
        );
    }

    dispatch(updateUser({ formValues, id }))
      .then(unwrapResult)
      .then(() => {
        navigate(-1);
        toastr.success('Succès!', 'Utilisateur mis à jour.');
      })
      .catch(() => toastr.error('Oups!', 'Une erreur s\'est produite. Veuillez réessayer.'));
  };

  const buttonClassName = classNames('form__button', {
    'form__button--disabled': isLoading,
  });

  return (
    <Container>
      <form
        className="form__form form__form--half-width"
        onSubmit={handleSubmit(handleFormSubmit)}
      >
        <div className="form__title form__title--left">
          {isEditPage ? 'Modifier les infos utilisateur' : 'Detail utilisateur'}
        </div>
        {isEditPage && authUser.id !== user.id && (
          <>
            <p className="form__status-text">État</p>
            <div className="form__toggle-switch-wrapper">
              <ToggleApproval isApproved={user.isApproved} userId={id} />
            </div>
          </>
        )}
        <Field
          name="firstName"
          type="text"
          placeholder="Prénom"
          label="Prénom"
          component={Input}
          readOnly={!isEditPage}
          validate={[required]}
        />
        <Field
          name="lastName"
          type="text"
          placeholder="Nom"
          label="Nom"
          component={Input}
          readOnly={!isEditPage}
          validate={[required]}
        />
        <Field
          name="username"
          type="email"
          placeholder="Email"
          label="Email"
          component={Input}
          readOnly
        />
        <Field
          name="job"
          type="text"
          placeholder="Poste"
          label="Poste"
          component={Input}
          readOnly={!isEditPage}
          validate={[required]}
        />
        <Field
          name="company"
          type="text"
          placeholder="Entreprise"
          label="Entreprise"
          component={Input}
          readOnly={!isEditPage}
          validate={[required]}
        />
        {!isEditPage && (
          <>
            <Field
              name="office"
              type="text"
              placeholder="Bureau"
              label="Bureau"
              component={Input}
              readOnly
            />
            <Field
              name="status"
              type="text"
              placeholder="État"
              label="État"
              component={Input}
              readOnly
            />
            <Field
              name="role"
              label="Rôle"
              type="text"
              component={Input}
              readOnly
            />
          </>
        )}
        {isEditPage && (
          <DisplayFor isGranted={isGranted(roles.ADMIN, authUser.roles)}>
            {getMainRole(user.roles) !== 'ROLE_OFFICE_MANAGER' && (
              <Field name="office" component={OfficeSelect} />
            )}
            {authUser.id !== user.id && (
              <>
                <label htmlFor="office" className="form__label">
                  Rôle
                </label>
                <Field name="role"
                  component={RoleSelect}
                  validate={[required]}
                />
              </>
            )}
          </DisplayFor>
        )}
        <div className="form__button-wrapper">
          {isEditPage ? (
            <>
              <div className="form__save-button-wrapper">
                <button disabled={isLoading} className={buttonClassName}>
                  Enregistrer
                </button>
                <Spinner loading={isLoading} overlay />
              </div>
              <button
                className="form__button form__button--grey form__button--small"
                type="button"
                onClick={() => navigate(-1)}
              >
                Annuler
              </button>
            </>
          ) : (
            <button
              className="form__button"
              type="button"
              onClick={() => navigate(-1)}
            >
              Retour
            </button>
          )}
        </div>
      </form>
    </Container>
  );
};

export default reduxForm({
  form: 'userDetailsForm'
})(UserDetails);
