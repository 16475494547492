import React, { useState } from 'react';
import { Field, reduxForm } from "redux-form";
import { useDispatch, useSelector } from 'react-redux';

import classNames from 'classnames';
import Input from '../../Form/Input';

import PasswordsMatch from '../../Auth/PasswordsMatch';
import Spinner from '../../Spinner/Spinner';
import { updateUser } from '../../../redux/thunks/usersThunks';
import { unwrapResult } from '@reduxjs/toolkit';
import { toastr } from 'react-redux-toastr';
import { required, minLength5, newPasswordMatch } from '../../../helpers/validation/fieldvalidation';


const ChangePasswordForm = ({ handleSubmit, submitting, reset }) => {

  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);
  const { id } = user;
  const [error, setError] = useState('');
  const buttonClassName = classNames('form__button', {
    'form__button--disabled': submitting,
  });



  const handleFormSubmit = (formValues) => {
    return dispatch(updateUser({ formValues, id: id }))
      .then(unwrapResult)
      .then(() => {
        setError('');
        reset();
        toastr.success('Votre mot de passe a été changé');
      })
      .catch((data) => {
        setError(data['hydra:description']);
      })
  }


  return (<form
    className="form__form"
    onSubmit={handleSubmit(handleFormSubmit)}
  >
    <p className="title">Mettre à jour votre mot de passe</p>
    <hr />

    <p className="form__input-error">{error}</p>
    <Field
      name="oldPassword"
      type="password"
      placeholder="Mot de passe"
      label="Mot de passe actuel"
      component={Input}
      validate={[required]}
    />

    <Field
      name="newPassword"
      type="password"
      placeholder="Nouveau mot de passe"
      label="Nouveau mot de passe"
      component={Input}
      validate={[required, minLength5]}
    />
    <Field
      name="passwordConfirm"
      type="password"
      placeholder="Confirmation"
      label="Confirmation"
      component={Input}
      validate={[required, newPasswordMatch, minLength5]}
    />
    <PasswordsMatch passwordFieldName='newPassword' formId="ChangePasswordForm" />

    <div className="form__button-wrapper">
      <div className="form__save-button-wrapper">
        <button disabled={submitting} className={buttonClassName}>
          Enregistrer
        </button>
        <Spinner loading={submitting} overlay />
      </div>
    </div>
  </form>
  )
}


export default reduxForm({ form: 'ChangePasswordForm' })(ChangePasswordForm);