import React from 'react';
import { useDispatch } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { unwrapResult } from '@reduxjs/toolkit';
import classnames from 'classnames';

import { updateUser } from '../../redux/thunks/usersThunks';

import './Toggle.sass';

const ToggleAgency = ({ isAgency, userId }) => {
  const dispatch = useDispatch();

  const onToggleAgencyClick = () => {
    let data = { formValues: { agency: !isAgency }, id: userId };
    dispatch(updateUser(data))
      .then(unwrapResult)
      .then(({agency}) => {
        toastr.success(`L'utilisateur est devenu ${agency ? 'interne' : 'externe'}`);
      })
      .catch(({ message }) => {
        toastr.error(
          'Oups!',
          message || 'Une erreur s\'est produite. Veuillez réessayer.'
        );
      });
  };

  const className = classnames('toggle-approval', {
    'toggle-approval--off': !isAgency,
  });

  return <div className={className} onClick={onToggleAgencyClick}></div>;
};

export default ToggleAgency;
