import React from 'react';

const InputError = ({ meta }) => {
  const { error, submitFailed } = meta;

  if (!error || !submitFailed) {
    return null;
  }

  return <div className="form__input-error">{error}</div>;
};

export default InputError;
