import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import classnames from 'classnames';

import Container from '../Container/Container';
import Spinner from '../Spinner/Spinner';
import ToggleSwitch from '../Toggles/ToggleApproval';
import Paginate from '../Paginate/Paginate';
import { getUsers } from '../../redux/thunks/usersThunks';
import { isGranted, roles } from '../../helpers/roles';
import ToggleAgency from '../Toggles/ToggleAgency';

const UsersList = () => {
  const {
    data: users,
    pagination,
    isLoading,
  } = useSelector(state => state.users);
  const authUser = useSelector(state => state.auth.user);
  const dispatch = useDispatch();
  const isLoadingNextPage = isLoading && users.length;
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const isUrlContains = useCallback(
    str => location.pathname.includes(str),
    [location.pathname]
  );

  useEffect(() => {
    if (isUrlContains('office')) {
      dispatch(
        getUsers({ page: 1, 'office.location': authUser.office.location,itemsPerPage: 100 })
      );
    } else {
      dispatch(getUsers({ page: 1 ,itemsPerPage: 100}));
    }
  }, [dispatch, isUrlContains, authUser.office.location]);

  if (isLoading && !users.length) {
    return (
      <Container>
        <Spinner loading />
      </Container>
    );
  }

  if (!users.length) {
    return <Container />;
  }

  const handlePageClick = event => {
    if (isUrlContains('office')) {
      dispatch(
        getUsers({
          page: event.selected + 1,
          'office.location': authUser.office.location,
          itemsPerPage: 100 
        })
      );
    } else {
      dispatch(getUsers({ page: event.selected + 1 ,itemsPerPage: 100 }));
    }
  };

  const isNotAdmin = user => !isGranted(roles.ADMIN, user.roles);

  const usersRows = users.map(user => {
    const { id, username, firstName, lastName, office, isApproved, agency } = user;

    return (
      <tr key={id}>
        <td>
          {firstName} {lastName}
        </td>
        <td>{username}</td>
        <td>{office.location}</td>
        {isUrlContains('manage') && (
          <td>
            {isNotAdmin(user) && authUser.id !== user.id && (
              <ToggleSwitch isApproved={isApproved} userId={id} />
            )}
          </td>
        )}
        {isUrlContains('manage') && (
          <td>
            {isNotAdmin(user) && authUser.id !== user.id && (
              <ToggleAgency isAgency={agency} userId={id} />
            )}
          </td>
        )}
        <td>
          {isUrlContains('manage') &&
            (isNotAdmin(user) || user.id === authUser.id) && (
              <Link
                state={{ prevRoute: location.pathname }}
                to={`/users/${id}/edit`}
                className="users-table__button"
              >
                Modifier
              </Link>
            )}
          <Link to={`/users/${id}`} className="users-table__button">
            Voir plus
          </Link>
        </td>
      </tr>
    );
  });

  const className = classnames('users-table', {
    'users-table--is-loading-next-page': isLoadingNextPage,
  });

  return (
    <Container>
      <div className={className}>
        <table>
          <thead>
            <tr>
              <th>Nom Prénom</th>
              <th>Email</th>
              <th>Bureau</th>
              {isUrlContains('manage') && <th>Statut</th>}
              {isUrlContains('manage') && <th>Ext/Int</th>}
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>{usersRows}</tbody>
        </table>
        <Spinner loading={isLoadingNextPage} overlay />
      </div>
      <Paginate pagination={pagination} handlePageClick={handlePageClick} />
    </Container>
  );
};

export default UsersList;
