import { isEmail } from 'validator';

const requiredMessage = 'Veuillez renseigner ce champ';
const mismatchMessage = 'Les mots de passe ne correspondent pas. Réessayez.';


export const required = value =>
    (value) ? undefined : requiredMessage


export const isNumber = value =>
    value && isNaN(Number(value)) ? 'doit être un nombre' : undefined


const minLengthValidate = min => value =>
    value && value.length < min ? `Le mot de passe doit contenir au moins ${min} caractères` : undefined

export const minLength5 = minLengthValidate(5)

export const email = value =>
    value && !isEmail(value)
        ? 'Mauvaise syntaxe d\'e-mail'
        : undefined


const PasswordMatch = fieldName => (value, allValues) => {
    return value !== allValues[fieldName] ? mismatchMessage : undefined;
}

export const passwordMatch = PasswordMatch('password');

export const newPasswordMatch = PasswordMatch('newPassword');