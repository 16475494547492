import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { reduxForm, Field } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { toastr } from 'react-redux-toastr';

import Input from '../Form/Input';
import Spinner from '../Spinner/Spinner';
import CosaVostraMeta from '../Ui/CosaVostraMeta';
import { changePassword } from '../../redux/thunks/authThunks';
import { minLength5, passwordMatch, required } from '../../helpers/validation/fieldvalidation';

const ChangePassword = ({ handleSubmit }) => {
  const { token: resetPasswordToken } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoading } = useSelector(state => state.auth);

  const handleFormSubmit = formValues => {
    dispatch(changePassword({ formValues, resetPasswordToken }, navigate))
      .then(unwrapResult)
      .then(response => {
        toastr.success('Cool!', response.data.message);
        navigate('/login');
      })
      .catch(e => {
        toastr.error('Sorry!', e.message);
      });
  };

  return (
    <div className="form">
      <div className="form__content-wrapper">
        <CosaVostraMeta />
        <form className="form__form" onSubmit={handleSubmit(handleFormSubmit)}>
          <div className="form__title">Password Change</div>
          <Field
            name="password"
            type="password"
            placeholder="Mot de passe"
            label="Mot de passe"
            component={Input}
            validate={[required, minLength5]}
          />
          <Field
            name="passwordConfirm"
            type="password"
            placeholder="Confirmation du mot de passe"
            label="Confirmation du mot de passe"
            component={Input}
            validate={[required, passwordMatch, minLength5]}
          />
          <div className="form__button-wrapper form__button-wrapper--mt">
            <button
              disabled={isLoading}
              className={`form__button ${isLoading ? 'form__button--disabled' : ''
                }`}
            >
              Changer votre mot de passe
            </button>
            <Spinner loading={isLoading} overlay />
          </div>
        </form>
      </div>
    </div>
  );
};

export default reduxForm({ form: 'registrationForm' })(
  ChangePassword
);
