import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { toggleRoomView } from '../../redux/slices/roomsSlice';

import './Room.sass';

const Room = ({ room }) => {
  const [checked, setChecked] = useState(true);
  const dispatch = useDispatch();

  const onCheckedChange = () => {
    setChecked(!checked);
    dispatch(toggleRoomView({ roomId: room.id, isChecked: !checked }));
  };

  return (
    <label className="room" htmlFor={`room-${room.id}`}>
      <input
        type="checkbox"
        id={`room-${room.id}`}
        onChange={onCheckedChange}
        checked={checked}
      />
      <div
        className="room__checkbox"
        style={{
          backgroundColor: checked ? room.eventColor : 'transparent',
          border: `2px solid ${room.eventColor}`,
          color: room.eventColor,
        }}
      >
        <i
          className="fa-solid fa-check"
          style={{ display: checked ? 'block' : 'none' }}
        />
      </div>
      <span>{room.title}  {room.size > 0   && 
         "( " + room.size + " pers )" }</span>
    </label>
  );
};

export default Room;
