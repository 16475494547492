import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { closeBooking, removeBooking } from '../../redux/thunks/bookingsThunks';
import { removeBook } from '../../redux/slices/bookingsSlice';
import { isGranted, isLocalOfficeManager, roles } from '../../helpers/roles';
import { capitalizeWords } from '../../helpers/strings';
import { SanitizeHTML } from '../Ui/Sanitize/SanitizeHTML';

import './Panel.sass';



const EventPanel = ({ event, resources, open, setOpen, handleEditEvent }) => {
  const dispatch = useDispatch();
  const ref = useRef();
  const authUser = useSelector(state => state.auth.user);
  const [style, setStyle] = useState({})

  useEffect(() => {
    const onOutSideBookingClick = e => {
      if (ref.current && open) {
        if (!ref.current.contains(e.target) && !event.el.contains(e.target)) {
          setOpen(false);
        }
      }
    };

    window.addEventListener('click', onOutSideBookingClick);
    return () => window.removeEventListener('click', onOutSideBookingClick);
  }, [event, open, setOpen]);

  const calculatePosition = () => {
    const eventPanelWidth = document.querySelector('.event-panel').clientWidth;
    const calendarWidth = document.querySelector('.container__content-wrapper').clientWidth;
    const left = (calendarWidth < event.jsEvent.pageX) ?
      calendarWidth - eventPanelWidth :
      event.jsEvent.pageX - eventPanelWidth;

    return {
      ...style,
      left: (left < 0) ? 0 : left,
      top: event?.jsEvent.pageY
    }


  }

  useEffect(() => {
    if (event) {

      window.matchMedia("(max-width: 700px)").matches ? setStyle({
        ...style,
        left: 0,
        top: event?.jsEvent.pageY
      }) : setStyle(calculatePosition());

    }
  }, [event])

  if (!event || !open) {
    return null;
  }

  const { bookedBy, endedAt, endDate, startDate, bookedByDisplayName, bookedByDescription } = event.event.extendedProps;
  const { start, end } = event.event._instance.range;
  const { title, resourceIds } = event.event._def;
  const roomId = parseInt(resourceIds[0]);
  const room = resources.find(r => r.id === roomId);
  const hasEnded = new Date() > new Date(endedAt ?? endDate + '+00:00');
  const isMyBooking = authUser.id === bookedBy?.id;
  const hasStarted = new Date() > new Date(startDate + '+00:00');
  const fullName = (isGranted('ROLE_SUPER_ADMIN', bookedBy.roles) && bookedByDisplayName) ? bookedByDisplayName.split('@') : null;

  const onDelete = () => {
    setOpen(false);
    dispatch(removeBooking({ bookingId: event.event.id }))
      .then(
        dispatch(removeBook({ bookingId: parseInt(event.event.id) }))
      )
  };

  const onClose = () => {
    setOpen(false);
    dispatch(closeBooking({ bookingId: event.event.id }));
  };

  return (
    <div
      className="event-panel"
      ref={ref}
      style={style}
    >
      <div className="event-panel__actions">
        {!hasEnded && (
          <>
            {isMyBooking && (
              <button onClick={() => handleEditEvent(event.event.id)}
                style={{ marginRight: '1em' }}
              >
                <i className="fa-solid fa-pencil"></i>
              </button>
            )}
            {(isMyBooking
              || isGranted(roles.ADMIN, authUser.roles)
              || isLocalOfficeManager(authUser, room.office.id)
            ) && (
                <i className="fa-solid fa-trash-can" onClick={onDelete}></i>
              )}
          </>
        )}
        <i className="fa-solid fa-xmark" onClick={() => setOpen(false)}></i>
      </div>
      <div className="event-panel__wrapper">
        <div
          className="event-panel__room-color"
          style={{ backgroundColor: room?.eventColor }}
        ></div>
        <div>
          <h1 className="event-panel__room-title">{room?.title}</h1>
          <p>{title}</p>
          <p>
            {'Le '}<span className='box'>{moment(start).locale('fr').utc().format('dddd, MMMM, D')}</span>

            de <span className='box'>{moment(start).utc().format('HH:mm')}
              &nbsp;-&nbsp;
              {moment(end).utc().format('HH:mm')}
            </span>
          </p>
          <p>
            Réservé par: {fullName ? capitalizeWords(fullName[0]) : `${bookedBy.firstName} ${bookedBy.lastName} (${bookedBy.company})`}  </p>
          <SanitizeHTML className="event-panel__description" html={bookedByDescription} />

          {isMyBooking && !hasEnded && hasStarted && (
            <button
              className="event-panel__close-button"
              onClick={onClose}
            >
              Fermer
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default EventPanel;