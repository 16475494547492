import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import FullCalendar from '@fullcalendar/react';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import scrollGridPlugin from '@fullcalendar/scrollgrid';
import moment from 'moment';

import { getBookingsByOfficeId } from '../../redux/thunks/bookingsThunks';
import { getUser } from '../../redux/thunks/authThunks';
import { getRooms } from '../../redux/thunks/roomsThunks';
import Container from '../Container/Container';
import Spinner from '../Spinner/Spinner';
import EventPanel from './EventPanel';
import RoomPanel from './RoomPanel';
import BookModal from '../Modal/BookModal'
import { toLocalString } from '../../helpers/dates';
import { isGranted } from '../../helpers/roles';
import { capitalizeWords } from '../../helpers/strings';

import './Calendar.sass';


const Calendar = () => {
  const { list: bookings, isLoading } = useSelector(state => state.bookings);
  const { list: rooms } = useSelector(state => state.rooms);
  const { googleAccessToken, ...authUser } = useSelector(state => state.auth.user);
  const dispatch = useDispatch();
  const location = useLocation();
  const calendarRef = useRef();

  const checkedRooms = rooms.filter(room => room.isChecked);
  const [clickedEvent, setClickedEvent] = useState(null);
  const [bookEvent, setBookEvent] = useState(null);
  const [roomEvent, setRoomEvent] = useState(null);
  const [room, setRoom] = useState(null);
  const [open, setOpen] = useState(true);
  const [roomPanel, setRoomPanel] = useState(false);
  const officeId = location.state?.officeId ?? authUser.office.id;
  const [openModal, setOpenModal] = useState(false);
  const [bookId, setBookId] = useState(null);



  const getBookings = useCallback(
    (startDate, endDate) => {
      dispatch(
        getBookingsByOfficeId({
          officeId: officeId,
          startDate: moment(startDate).format('YYYY-MM-DD 00:00:00'),
          endDate: moment(endDate).format('YYYY-MM-DD 00:00:00'),
        })
      );

    },
    [dispatch, officeId]
  );

  useEffect(() => {
    dispatch(getUser());
  },[dispatch]);

  useEffect(() => {
    const intervalID = setInterval(() => {
      let { start, end } = calendarRef.current?.getApi().currentDataManager.data.dateProfile.currentRange;
      getBookings(moment(start), moment(end));
    }, 10000);

    return () => clearInterval(intervalID);
  }, [officeId]);

  useEffect(() => {
    const today = moment().startOf('day');
    const tomorrow = moment().add(1, 'days').startOf('day');
    getBookings(today, tomorrow);
  }, [getBookings, officeId]);

  useEffect(() => {
    dispatch(getRooms({ pagination: false, officeId }));
  }, [officeId])

  const events = bookings
    .map(booking => ({
      ...booking,
      resourceId: booking.room.id,
      start: toLocalString(booking.startDate),
      end: booking.endedAt
        ? toLocalString(booking.endedAt)
        : toLocalString(booking.endDate),
    }));



  const onCalendarClick = e => {

    if (e.view.type === 'dayGridMonth') {
      calendarRef.current.getApi().changeView('resourceTimeGridDay', e.dateStr);
    }

  };

  const onEventClick = e => {
    setOpen(true);
    setClickedEvent(e);
  };

  const onDateSelect = e => {
    setBookId(null);
    setBookEvent(e);
    setOpenModal(!openModal);
  }

  const handleEditEvent = (bookId) => {
    setOpenModal(!openModal);
    setBookId(bookId);
  }

  const onEventMount = event => {
    if (event.event.extendedProps.bookedBy) {
      const { firstName, id, company, roles } = event.event.extendedProps.bookedBy;
      const { bookedByDisplayName } = event.event.extendedProps;
      let BookedBy = firstName;
      if (isGranted('ROLE_SUPER_ADMIN', roles) && bookedByDisplayName) {
        const [fullName] = bookedByDisplayName.split('@');
        BookedBy = capitalizeWords(fullName);

      } else if (authUser.id === id) {
        BookedBy = 'Moi';
      }

      event.el
        .querySelector('.fc-event-title-container')
        ?.insertAdjacentHTML('beforeend', `<div>Réservé par: ${BookedBy} (${company})</div>`);

    }

  };

  const handleCloseModal = () => {
    setOpenModal(false);
  }

  const showRoom = (arg) => {
    const el = arg.el;

    el.addEventListener('click', (e) => {
      const columnHeader = e.target.closest('th.fc-col-header-cell.fc-resource');


      const room = rooms.find(
        b => b.id === parseInt(columnHeader.dataset.resourceId)
      );
      setRoom(room)
      setRoomEvent(e)
      setRoomPanel(true)

    })
  }



  return (
    <Container>
      <FullCalendar
        ref={calendarRef}
        plugins={[
          interactionPlugin,
          dayGridPlugin,
          resourceTimeGridPlugin,
          scrollGridPlugin,
        ]}
        initialView="resourceTimeGridDay"
        headerToolbar={{
          left: window.innerWidth > 500 ? 'today' : '',
          center: 'prev,title,next',
          right: 'resourceTimeGridDay,resourceTimeGridFourDay,dayGridMonth',
        }}
        views={{
          resourceTimeGridDay: {
            buttonText: 'Jour',
            resourceLabelDidMount: (arg) => showRoom(arg),
            selectable: true,
            select: (e) => onDateSelect(e)
          },
          resourceTimeGridFourDay: {
            type: 'resourceTimeGrid',
            duration: { days: 5 },
            buttonText: 'Semaine',
            dayMinWidth: 70,
            selectable: true,
            select: (e) => onDateSelect(e),
          },
          dayGridMonth: {
            buttonText: 'Mois',
          },
        }}
        schedulerLicenseKey="GPL-My-Project-Is-Open-Source"
        resources={checkedRooms}
        events={events}
        nowIndicator
        allDaySlot={false}
        slotMinTime="08:00:00"
        height="auto"
        locale="fr"
        datesSet={({ startStr, endStr }) => { getBookings(startStr, endStr) }}
        dateClick={onCalendarClick}
        buttonText={{ today: 'Aujourd’hui' }}
        eventClick={onEventClick}
        eventDidMount={onEventMount}
        firstDay={1}
        dayMinWidth={150}
        stickyHeaderDates={true}
        stickyFooterScrollbar={true}
        selectAllow={(event) => (
          event.start.getDate() === event.end.getDate()
          && event.start.getTime() >= new Date().getTime()
        )
        }

      />
      <Spinner loading={isLoading} overlay toTop />
      <EventPanel
        open={open}
        setOpen={setOpen}
        resources={checkedRooms}
        event={clickedEvent}
        handleEditEvent={handleEditEvent}
      />
      <RoomPanel
        roomPanel={roomPanel}
        setRoomPanel={setRoomPanel}
        room={room}
        event={roomEvent}
      />
      <BookModal
        openModal={openModal}
        officeId={officeId}
        handleCloseModal={handleCloseModal}
        calendar={calendarRef}
        bookId={bookId}
        event={bookEvent}
      />

    </Container>
  );
};

export default Calendar;
