import React from 'react';
import classnames from 'classnames';

import './Spinner.sass';

const Spinner = ({ loading, overlay, toTop }) => {
  if (!loading) {
    return null;
  }

  const className = classnames('spinner', {
    'spinner--overlay': overlay,
    'spinner--to-top': toTop,
  });

  return (
    <div className={className}>
      <i className="fa-solid fa-circle-notch"></i>
    </div>
  );
};

export default Spinner;
