import React, { useState } from 'react';
import { Field } from 'redux-form';
import { useSelector } from 'react-redux';

import RoomSelect from './RoomSelect';
import { required } from '../../helpers/validation/fieldvalidation';

const OfficeRoomsSelect = ({ officeLabel, defaultOffice, defaultRoomId }) => {
    const offices = useSelector(state => state.offices.list);
    const [rooms, setRooms] = useState(defaultOffice.rooms ?? [])
    const [officeId, setOfficeId] = useState(defaultOffice.id)
    const [roomId, setRoomId] = useState(defaultRoomId ?? defaultOffice.rooms[0].id)
    return (
        <div>
            <label htmlFor="officeName" className="form__label">
                {officeLabel}
            </label>
            <select
                className="form__input form__select"
                value={officeId}
                onChange={event => {
                    const officeIndex = offices.findIndex(office => office.id == event.target.value);
                    setRooms(offices[officeIndex].rooms);
                    setOfficeId(event.target.value)
                    setRoomId(offices[officeIndex].rooms[0].id)

                }
                }
            >
                {offices.map(office => (
                    <option value={office.id} key={office.id}>
                        {office.location}
                    </option>)
                )}
            </select>
            <Field
                name="room"
                label="Sélectionner une salle"
                rooms={rooms}
                defaultValue={roomId}
                officeId={officeId}
                component={RoomSelect}
                validate={[required]}
            />
        </div>
    );
};

export default OfficeRoomsSelect;