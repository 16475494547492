import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Container from "../../components/Container/Container"
import AccountForm from "../../components/Users/User/AccountForm"
import ChangePasswordForm from "../../components/Users/User/ChangePasswordForm"


import './Account.sass'

const Account = () => {
  const user = useSelector(state => state.auth.user);

  return <Container>
    <div className="account__content">
      <div className="card">
        <div className="d-flex account">
          <div className="col user__info">
            <p className='title'>Informations sur votre profil</p>
            <hr />
            <p><span className="title">Prénom :</span> {user.firstName}  </p>
            <p><span className="title"> Nom :</span>{user.lastName}</p>
            <p><span className="title">Email :</span> {user.username} </p>
            <p><span className="title">Poste :</span> {user.job} </p>
            <p><span className="title">Entreprise :</span> {user.company} </p>
            <p><span className="title">Bureau :</span> {user.office.location} </p>

          </div>
          <div className="col">
            <AccountForm />
          </div>
        </div>
      </div>
      <div className="account--password">
        <ChangePasswordForm />
      </div>
      <div className="account__delete">
        <p>En cas de besoin de supprimer votre compte, vous pouvez <Link to="/delete-account">cliquer ici</Link>.</p>
      </div>

    </div>

  </Container>
}

export default Account