import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MaterialTable from 'material-table';
import { ThemeProvider, createTheme } from '@mui/material';
import { unwrapResult } from '@reduxjs/toolkit';


import { getRooms, removeRoom } from '../../redux/thunks/roomsThunks';
import { localization } from '../../helpers/material-table/localization';
import OfficeSelectFilter from '../../components/Material/FIlter/OfficeSelectFilter';
import Container from '../../components/Container/Container';
import RoomModal from '../../components/Modal/RoomModal';
import AlertDialog from '../../components/Dialog/ALertDialog';
import { removeRoomById } from '../../redux/slices/roomsSlice';
import { getPageNumber } from '../../helpers/strings';

import '../../styles/components/_rooms-table.sass'


const RoomList = () => {
  const defaultMaterialTheme = createTheme();
  const tableRef = React.useRef();
  const dispatch = useDispatch();
  const { list: offices } = useSelector(state => state.offices);
  const [roomId, setRoomId] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [open, setOpen] = React.useState(false);

  const handleCloseModal = () => {
    setOpenModal(false);
  }


  const confirmAction = () => {
    dispatch(removeRoom({ roomId: roomId }))
      .then(dispatch(removeRoomById({ roomId })))
    setOpen(false);
    refreshData();
  }

  const refreshData = () => tableRef.current && tableRef.current.onQueryChange();

  return (
    <Container>
      <ThemeProvider theme={defaultMaterialTheme}>
        <MaterialTable
          title=""
          tableRef={tableRef}
          columns={[
            {
              title: 'Salle',
              field: 'title',
              filtering: true,

            },
            {
              title: 'Bureau',
              field: 'office.id',
              filtering: true,
              filterComponent: ({ columnDef, onFilterChanged }) => (
                <OfficeSelectFilter
                  columnDef={columnDef}
                  onFilterChanged={onFilterChanged}
                  offices={offices}
                />
              ),
              render: rowData => rowData.office.location
            },
            {
              title: 'Nombre de personnes',
              field: 'size', render: rowData => `${rowData.size} Personnes`,
              filtering: true,
              type: 'numeric'
            },
            {
              title: 'Description',
              field: 'description',
              filtering: false
            },

          ]}
          data={query =>
            new Promise((resolve, reject) => {
              dispatch(getRooms({
                itemsPerPage: query.pageSize,
                search: query.search,
                filters: query.filters,
                page: query.page + 1
              }))
                .then(unwrapResult)
                .then((payload) => {


                  resolve({
                    data: payload.data['hydra:member'].map(o => ({ ...o })),
                    page: getPageNumber(payload.data['hydra:view']['@id']) - 1,
                    totalCount: parseInt(payload.data['hydra:totalItems']),
                  })
                }

                )
            })
          }
          localization={{
            ...localization,
            toolbar: {
              ...localization.toolbar,
              searchPlaceholder: 'Chercher par salle,bureau',
            },
            pagination: {
              ...localization.pagination,
              labelRowsPerPage: '',
            }
          }}

          actions={[
            rowData => ({
              icon: 'edit',
              iconProps: {
                baseClassName: 'fa-solid fa-pen',
                color: 'primary',
                fontSize: 'small'
              },
              onClick: (event) => {
                setOpenModal(true);
                setRoomId(rowData.id);
              }


            }),
            rowData => ({
              icon: 'delete',
              iconProps: {
                baseClassName: 'fa-solid fa-trash',
                color: "primary",
                variant: "contained",
                fontSize: 'small'
              },
              onClick: (event) => {
                setOpen(true);
                setRoomId(rowData.id);
              }

            }),

            {
              icon: () => <span className='btn btn-blue'><i className='fa fa-plus'></i>Ajouter une salle</span>,
              isFreeAction: true,
              onClick: (event) => {
                setOpenModal(true)
                setRoomId(null);
              }
            }
          ]}
          options={{
            actionsColumnIndex: -1,
            headerStyle: { border: '1px solid #eee', textAlign: 'left', fontWeight: 600 },
            cellStyle: { border: '1px solid #eee', textAlign: 'left' },
            tableLayout: 'auto',
            searchFieldAlignment: 'left',
            sorting: false,
            filtering: true,
            searchFieldStyle: { height: '100%' },
            pageSize: 10,
          }}
        />
      </ThemeProvider>
      <RoomModal
        openModal={openModal}
        roomId={roomId}
        handleCloseModal={handleCloseModal}
        refreshData={refreshData}
      />
      <AlertDialog
        open={open}
        setOpen={setOpen}
        confirmAction={confirmAction}
      />
    </Container>


  );
}
export default RoomList;