import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ReduxToastr from 'react-redux-toastr';

import Account from '../../pages/User/Account';
import Calendar from '../Calendar/Calendar';
import ChangePassword from '../Auth/ChangePassword';
import DeleteAccount from '../../pages/DeleteAccount/DeleteAccount';
import GuestRoute from '../Routes/GuestRoute';
import Login from '../Auth/Login';
import PrivateRoute from '../Routes/PrivateRoute';
import Register from '../Auth/Register';
import ResetPassword from '../Auth/ResetPassword';
import RoomList from '../../pages/Room/RoomList';
import UserDetails from '../Users/UserDetails';
import UsersList from '../Users/UsersList';

import './App.sass';

const App = () => (
  <BrowserRouter>
    <Routes>
      <Route
        path="/"
        element={
          <GuestRoute>
            <Login />
          </GuestRoute>
        }
      />
      <Route
        path="/login"
        element={
          <GuestRoute>
            <Login />
          </GuestRoute>
        }
      />
      <Route
        path="register"
        element={
          <GuestRoute>
            <Register />
          </GuestRoute>
        }
      />

      <Route
        path="/password/reset"
        element={
          <GuestRoute>
            <ResetPassword />
          </GuestRoute>
        }
      />
      <Route
        path="/password/change/:token"
        element={
          <GuestRoute>
            <ChangePassword />
          </GuestRoute>
        }
      />
      <Route
        path="/calendar"
        element={
          <PrivateRoute>
            <Calendar />
          </PrivateRoute>
        }
      />
      {['/users', '/users/office/manage', '/users/manage'].map((path, key) => (
        <Route
          key={key}
          path={path}
          element={
            <PrivateRoute>
              <UsersList />
            </PrivateRoute>
          }
        />
      ))}
      {['/users/:id/edit', '/users/:id'].map((path, key) => (
        <Route
          key={key}
          path={path}
          element={
            <PrivateRoute>
              <UserDetails />
            </PrivateRoute>
          }
        />
      ))}
      <Route
        path="/my-account"
        element={
          <PrivateRoute>
            <Account />
          </PrivateRoute>
        }
      />
      <Route
        path="/rooms"
        element={
          <PrivateRoute>
            <RoomList />
          </PrivateRoute>
        }
      />
      <Route
        path="/delete-account"
        element={
          <PrivateRoute>
            <DeleteAccount />
          </PrivateRoute>
        }
      />
    </Routes>
    <ReduxToastr
      timeOut={4000}
      newestOnTop={false}
      preventDuplicates
      position="top-right"
      getState={state => state.toastr}
      transitionIn="fadeIn"
      transitionOut="fadeOut"
      closeOnToastrClick
    />
  </BrowserRouter>
);

export default App;
