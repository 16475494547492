

export const areFilesExtensionValid = (files) => {


  const validExtensions = ['png', 'jpeg', 'jpg'];

  for (let i = 0; i < files.length; i++) {
    if (!validExtensions.includes(files[i].type.split('/')[1])) {
      return false;
    }
  }

  return true;

}