import React from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ children, isLoggedIn }) => {
  return !isLoggedIn ? children : <Navigate to="/calendar" />;
};

const mapStateToProps = state => ({ isLoggedIn: state.auth.isLoggedIn });

export default connect(mapStateToProps)(PrivateRoute);
