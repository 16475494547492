import Office from "./Office";
import React, {useEffect} from "react";
import {getOffices} from "../../redux/thunks/officesThunks";
import {useDispatch, useSelector} from "react-redux";

const Offices = ({ officeId, setOfficeId }) => {
    const dispatch = useDispatch();
    const {list: offices} = useSelector(state => state.offices);
    const [openedOfficeId, setOpenedOfficeId] = React.useState(officeId);

    useEffect(() => {
        dispatch(getOffices());
    }, [dispatch]);

    return (
        <>
            {offices.map((office) =>
                <Office
                    key={office.id}
                    office={office}
                    isVisible={openedOfficeId === office.id}
                    setOpenedOfficeId={setOpenedOfficeId}
                    setOfficeId={setOfficeId}
                    officeId={openedOfficeId}
                />
            )}
        </>
    )
}

export default Offices;
