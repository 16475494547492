import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';
import { toastr } from 'react-redux-toastr';

import Spinner from '../Spinner/Spinner';
import Input from '../Form/Input';
import CosaVostraMeta from '../Ui/CosaVostraMeta';
import { resetPassword } from '../../redux/thunks/authThunks';
import { email, required } from '../../helpers/validation/fieldvalidation';

const ResetPassword = ({ handleSubmit }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading } = useSelector(state => state.auth);

  const handleFormSubmit = async formValues => {
    dispatch(resetPassword(formValues))
      .then(unwrapResult)
      .then(response => {
        toastr.success('Success!', response.data.message);
        navigate('/login');
      })
      .catch(() => {
        toastr.error(
          'Oups!',
          "Quelque chose s'est mal passé, réessayez plus tard."
        );
      });
  };

  return (
    <div className="form">
      <div className="form__content-wrapper">
        <CosaVostraMeta />
        <form className="form__form" onSubmit={handleSubmit(handleFormSubmit)}>
          <div className="form__title">Reset Password</div>
          <Field
            name="username"
            type="email"
            placeholder="Email"
            label="Email"
            component={Input}
            validate={[required, email]}
          />
          <div className="form__button-wrapper form__button-wrapper--mt">
            <button
              disabled={isLoading}
              className={`form__button ${isLoading ? 'form__button--disabled' : ''
                }`}
            >
              Recevoir un mail
            </button>
            <Spinner loading={isLoading} overlay />
          </div>
        </form>
      </div>
    </div>
  );
};

export default reduxForm({ form: 'resetPasswordForm' })(
  ResetPassword
);
