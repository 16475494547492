import { createAsyncThunk } from '@reduxjs/toolkit';

import server from '../../services/server';
import catcher from './catcher';
import { getAuth } from './helpers';

export const getUsers = createAsyncThunk(
  'users/getUsers',
  catcher(async (arg, { getState }) => {
    let queryParams = '?';

    for (const queryParam in arg) {
      queryParams += `${queryParam}=${arg[queryParam]}&`;
    }

    const response = await server.get(
      `/users${queryParams}`,
      getAuth(getState)
    );

    return response.data;
  })
);

export const getUserById = createAsyncThunk(
  'users/getUserById',
  catcher(async ({ id }, { getState }) => {
    const response = await server.get(`/users/${id}`, getAuth(getState));

    return response.data;
  })
);

export const updateUser = createAsyncThunk(
  'users/pathUser',
  catcher(async (arg, { getState }) => {
    const { formValues, id } = arg;

    const response = await server.put(
      `/users/${id}`,
      formValues,
      getAuth(getState)
    );

    return response.data;
  })
);

export const updateApproval = createAsyncThunk(
  'users/updateApproval',
  catcher(async (arg, { getState }) => {
    const { userId, isApproved } = arg;

    const response = await server.put(
      `/users/${userId}/approve/update`,
      { newApprovalStatus: isApproved },
      getAuth(getState)
    );

    return response.data;
  })
);

export const updateUserRole = createAsyncThunk(
  'users/updateUserRole',
  catcher(async ({ role, id }, { getState }) => {
    const auth = getAuth(getState);

    const response = await server.put(`/users/${id}/role`, { role }, auth);

    return response.data;
  })
);

export const getCalendarList = createAsyncThunk(
  'user/calendars',
  catcher(async (_arg, { getState }) => {
 
    const response = await server.get('/user/calendars', getAuth(getState));
    return response.data;
  })
);