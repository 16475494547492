import React from 'react';

const RememberMeCheckBox = ({ input }) => (
  <input
    {...input}
    type="checkbox"
    id="remember"
    className="form__checkbox"
    defaultChecked
  />
);
export default RememberMeCheckBox;
