import { getAuth } from './helpers';
import catcher from './catcher';
import server from '../../services/server';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getRoomsByOfficeId = createAsyncThunk(
  'rooms/getRoomsByOfficeId',
  catcher(async ({ officeId }, { getState }) => {
    return server.get(`rooms?office.id=${officeId}`, getAuth(getState));
  })
);

export const getRooms = createAsyncThunk(
  'rooms/getRooms',
  catcher(async ({ search, filters, itemsPerPage, page, pagination, officeId }, { getState }) => {
    let queryParams = '?';
    filters?.forEach((filter) => {
      queryParams += `${filter.column.field}=${filter.value}&`;
    })

    if (search && queryParams.indexOf('title') === -1) {
      queryParams += `title=${search}&office.location=${search}&`
    }
    if (itemsPerPage) {
      queryParams += `itemsPerPage=${itemsPerPage}&page=${page}&`;
    }

    if (pagination === false) {
      queryParams += `pagination=${pagination}&`;
    }

    if (officeId && queryParams.indexOf('office.id') === -1) {
      queryParams += `office.id=${officeId}`;
    }

    return server.get(`rooms${queryParams}`, getAuth(getState));
  })
);

export const removeRoom = createAsyncThunk(
  'rooms/remove',
  catcher(async ({ roomId }, { getState }) => {
    return server.delete(`rooms/${roomId}`, getAuth(getState));
  })
);

export const addRoom = createAsyncThunk(
  'rooms/add',
  catcher(async ({ data }, { getState }) => {
    return server.post('/rooms', data, getAuth(getState));
  })
);

export const updateRoom = createAsyncThunk(
  'rooms/add',
  catcher(async ({ data, roomId }, { getState }) => {
    return server.put(
      `/rooms/${roomId}`,
      data,
      getAuth(getState)
    );
  })
);

export const uploadImages = createAsyncThunk(
  'rooms/add',
  catcher(async ({ roomId, formData }, { getState }) => {
    return server.post(`rooms/${roomId}/images`, formData, getAuth(getState)
    );
  })
);

export const removeImage = createAsyncThunk(
  'image/remove',
  catcher(async ({ mediaId }, { getState }) => {
    return server.delete(`media_objects/${mediaId}`, getAuth(getState));
  })
);