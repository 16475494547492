import React, { useEffect, useRef, useState } from 'react';
import {  useDispatch, useSelector } from 'react-redux';

import { isGranted, roles } from '../../helpers/roles';
import { removeRoomById } from '../../redux/slices/roomsSlice';
import { removeRoom } from '../../redux/thunks/roomsThunks';
import { SERVER_URL } from '../../services/server';
import RoomModal from '../Modal/RoomModal';


import './Panel.sass';


const RoomPanel = ({ room, roomPanel, setRoomPanel, event }) => {
  const ref = useRef();
  const authUser = useSelector(state => state.auth.user);
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [style, setStyle] = useState({ 'zIndex': 1000, 'position': 'fixed' })

  useEffect(() => {

    const onOutSideClick = e => {
      if(ref.current && roomPanel){ 
        if (!ref.current.contains(e.target) && !event.target.contains(e.target) && openModal) {
          setRoomPanel(false);   
        }
      }

  
    };

    window.addEventListener('click', onOutSideClick);
    return () => window.removeEventListener('click', onOutSideClick);
  }, [event, roomPanel, setRoomPanel]);


  const calculatePosition = () => {
    const columnHeaderPositions = event.target.closest('th.fc-col-header-cell.fc-resource').getBoundingClientRect();
    const left = columnHeaderPositions.left;
    const top = columnHeaderPositions.top
    const calendarWidth = document.querySelector('.container__content-wrapper').clientWidth;

    return {
      ...style,
      left: (calendarWidth-left>0) ? left: '70%',
      top: top + columnHeaderPositions.height
    }

  }


  useEffect(() => {
    if (event) {

      window.matchMedia("(max-width: 700px)").matches ? setStyle({
        ...style,
        left: 0,
        top: 0
      }) : setStyle(calculatePosition());

    }
  }, [event])


  if (!room || (!roomPanel && !openModal)) {
    return null;
  }

  return (
 <>
    <div
      className="event-panel event-panel--room"
      ref={ref}
      style={style}
    >
    
      <div className="event-panel__actions">

        {isGranted(roles.OFFICE_MANAGER, authUser.roles) && (
          <>
            <button
              style={{ marginRight: '1em' }}
            >
              <i className="fa-solid fa-pencil" onClick={() => {
                          setRoomPanel(false);
                          setOpenModal(true);

              } }></i>
            </button>

            <i className="fa-solid fa-trash-can" onClick={() => {
              dispatch(removeRoom({roomId:room.id}))
              .then(
                dispatch(removeRoomById({roomId:room.id}))
              )
              setRoomPanel(false);
            }}></i>

           
          </>
        )}
        <i className="fa-solid fa-xmark" onClick={() => setRoomPanel(false)}></i>
        <p className='room__title'>{ room.title } - 
        <span className='room__title--office'> {room.office.location}</span>
        </p>

      </div>
      <div className="event-panel__wrapper--room">
          <p>
          <span className='title'>Description : </span>{room.description}
          </p>
          <p>
          <span className='title'>Nombre de personnes : </span>{room.size} personnes
          </p>
          <div className='room__images'>
          {room?.images.map(image => 
          <img key={image.id} src={`${SERVER_URL}${image.contentUrl}`} className="images__item" alt="" />
          )}

          </div>
      </div>

    </div>
    <RoomModal
        openModal={openModal}
        roomId={room.id}
        handleCloseModal={() => setOpenModal(false)}
      />
      </>
 
  );
};

export default RoomPanel;