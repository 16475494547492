import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function AlertDialog({open,setOpen,confirmAction}) {



  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        className='text-center'
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth='sm'
        fullWidth={true}
      >
        <DialogTitle id="alert-dialog-title text-primary">
        Êtes-vous sûr de vouloir supprimer cette Salle ?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          La suppression de salle sera irreversible
          </DialogContentText>
        </DialogContent>
        <DialogActions className='w-50 mx-auto'>
          <button className='btn btn-secondary' onClick={handleClose}>Annuler</button>
          <button className='btn btn-primary' onClick={confirmAction} autoFocus>
          Confirmer
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
