import React from 'react';

import InputError from './InputError';

const Input = ({ input, meta, label, ...rest }) => (
  <div>
    <label htmlFor="latName" className="form__label">
      {label}
    </label>
    <div>
      <input className="form__input" id={input.name} {...input} {...rest} />
      <InputError meta={meta} />
    </div>
  </div>
);

export default Input;
