import { createAsyncThunk } from '@reduxjs/toolkit';

import server from '../../services/server';
import catcher from './catcher';
import { getAuth } from './helpers';

export const refreshToken = createAsyncThunk(
  'auth/refreshToken',
  catcher(async () => {
    const token = localStorage.getItem('token');
    const rt = localStorage.getItem('refreshToken');

    if (!token || !rt) {
      return '';
    }

    const { data } = await server.post(
      '/token/refresh',
      { refreshToken: rt },
    );

    localStorage.setItem('token', data.token);
    localStorage.setItem('refreshToken', data.refreshToken);

    return data.token;
  })
);

export const login = createAsyncThunk(
  'auth/login',
  catcher(async formValues => {
    const { rememberMe, username, password } = formValues;

    const { data } = await server.post('/login_check', {
      username,
      password,
    });

    const { token, refreshToken: rt } = data;

    const { data: user } = await server.get('/users/me', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (rememberMe) {
      localStorage.setItem('token', token);
      localStorage.setItem('refreshToken', rt);
      localStorage.setItem('user', JSON.stringify(user));
    }

    return { user, token, refreshToken: rt };
  })
);

export const register = createAsyncThunk(
  'auth/register',
  catcher(async formValues => {
    return server.post('/users', formValues);
  })
);

export const resetPassword = createAsyncThunk(
  'auth/resetPassword',
  catcher(async formValues => {
    return server.post('/password/reset', formValues);
  })
);

export const changePassword = createAsyncThunk(
  'auth/changePassword',
  catcher(async ({ formValues, resetPasswordToken }) => {
    return server.post('/password/change', {
      resetPasswordToken,
      ...formValues,
    });
  })
);

export const getUser = createAsyncThunk(
  'user/me',
  catcher(async (_arg, { getState }) => {
    const res = await server.get('/users/me', getAuth(getState));
    localStorage.setItem('user', res.data);

    return res;
  })
);

export const googleLogin = createAsyncThunk(
  'users/connect',
  catcher(async ({ code }, { getState }) => {
    return server.get(`/users/connect?code=${code}`, getAuth(getState))
  })
);

export const googleLogout = createAsyncThunk(
  'users/logout',
  catcher(async (arg, { getState }) => {
    return server.get(`/users/logout`, getAuth(getState))
  })
);
