export const roles = {
  ADMIN: 'ROLE_ADMIN',
  OFFICE_MANAGER: 'ROLE_OFFICE_MANAGER',
  USER: 'ROLE_USER',
  SUPER_ADMIN: 'ROLE_SUPER_ADMIN'
};

function getUserRolesHierarchy(userRoles) {
  if (userRoles.includes(roles.SUPER_ADMIN)) {
    return [roles.SUPER_ADMIN,roles.ADMIN, roles.OFFICE_MANAGER, roles.USER];
  } else if (userRoles.includes(roles.ADMIN)) {
    return [roles.ADMIN, roles.OFFICE_MANAGER, roles.USER];
  } else if (userRoles.includes(roles.OFFICE_MANAGER)) {
    return [roles.OFFICE_MANAGER, roles.USER];
  }

  return [roles.USER];
}

export function isGranted(role, userRoles = []) {
  const userRolesHierarchy = getUserRolesHierarchy(userRoles);

  return userRolesHierarchy.includes(role);
}

export function getMainRole(userRoles = []) {
  return getUserRolesHierarchy(userRoles)[0];
}

export function isLocalOfficeManager(user,officeId){
  const userRolesHierarchy = getUserRolesHierarchy(user.roles);
  return userRolesHierarchy.includes(roles.OFFICE_MANAGER) && user.office.id === officeId;
}
