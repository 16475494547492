import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import InputError from '../Form/InputError';
import { getOffices } from '../../redux/thunks/officesThunks.js';

const OfficeSelect = ({ meta, input,value,...rest  }) => {
  const offices = useSelector(state => state.offices.list);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getOffices());
  }, [dispatch]);

  const officeOptions = offices.map(office => (
    <option value={office['id']} key={office.id}>
      {office.location}
    </option>
  ));

  return (
    <div>
    <label htmlFor="officeName" className="form__label">Sélectionner un bureau</label>
      <select
        className="form__input form__select"
        onChange={e => input.onChange(e.target.value)}
        onBlur={() => input.onBlur(input.value)}
        {...input}
   
      >
        {officeOptions}
      </select>
      <InputError meta={meta} />
    </div>
  );
};

export default OfficeSelect;
