export const toLocal = UTCDate => {
    return new Date(new Date(UTCDate.replace(/ /g,"T") + '+00:00').toISOString());
};

export const getStartHour = (offset = 0) => {
    return new Date().setHours(9, offset, 0, 0);
};

export const combineDates = (date, passedDate) => {
  const newDate = new Date(passedDate);

  const day = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();

  newDate.setDate(day);
  newDate.setMonth(month);
  newDate.setFullYear(year);

  return newDate;
};

export const toLocalString = date => {
  return new Date(date.replace(/ /g,"T") + '+00:00').toISOString()
};
