import { createSlice } from '@reduxjs/toolkit';

import { getRooms, getRoomsByOfficeId, addRoom, updateRoom } from '../thunks/roomsThunks';
import { setLoadingOff } from './helpers';

const initialState = {
  list: [],
  all: []
};

const colors = [
  '#4285F4',
  '#9A4BC0',
  '#FBBC05',
  '#34A853',
  '#EA4335',
  '#3B4452',
  '#cc1674',
];

const roomsSlice = createSlice({
  name: 'rooms',
  initialState,
  reducers: {
    toggleRoomView: (state, action) => {
      const { roomId, isChecked } = action.payload;

      const roomIndex = state.list.findIndex(room => room.id === roomId);

      state.list[roomIndex].isChecked = isChecked;
    },
    removeRoomById: (state, { payload }) => {
      const index = state.list.findIndex(
        room => room.id === payload.roomId
      );

      if (index !== -1) {
        state.list.splice(index, 1);
      }
    },
    removeRoomImageById: (state, { payload }) => {
      const { roomId, imageId } = payload;
      const roomIndex = state.all.findIndex(
        room => room.id === roomId
      );

      if (roomIndex !== -1) {
        state.all[roomIndex].images = state.all[roomIndex].images.filter(img => img.id !== imageId);
      }
    }
  },
  extraReducers: {
    [getRoomsByOfficeId.fulfilled]: (state, action) => {
      state.list = action.payload.data['hydra:member'].map((room, i) => ({
        ...room,
        eventColor: colors[i],
        isChecked: true,
      }));
    },
    [getRooms.fulfilled]: (state, action) => {
      state.all = action.payload.data['hydra:member'];
    },
    [addRoom.fulfilled]: (state, { payload }) => {
      setLoadingOff(state);
      const room = payload.data;
      state.all.push(room);
      state.list.push({
        ...room,
        eventColor: colors[0],
        isChecked: true
      });
    },
    [addRoom.rejected]: setLoadingOff,
    [updateRoom.fulfilled]: (state, { payload }) => {

      const listIndexToUpdate = state.list.findIndex(room => room.id === payload.data.id);
      const allIndexToUpdate = state.all.findIndex(room => room.id === payload.data.id);

      if (listIndexToUpdate !== -1) {
        state.list[listIndexToUpdate] = {
          ...payload.data,
          eventColor: colors[0],
          isChecked: true,
        };
      }

      if (allIndexToUpdate !== -1) {
        state.all[allIndexToUpdate] = payload.data;
      }

    },
  },
});

export const { toggleRoomView, removeRoomById, removeRoomImageById } = roomsSlice.actions;

export default roomsSlice.reducer;
