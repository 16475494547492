import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm } from "redux-form";
import { toastr } from 'react-redux-toastr';
import { unwrapResult } from '@reduxjs/toolkit';

import classNames from 'classnames';
import Input from '../../Form/Input';
import Spinner from '../../Spinner/Spinner';
import { required } from '../../../helpers/validation/fieldvalidation'
import { updateUser } from '../../../redux/thunks/usersThunks';
import { getUser } from '../../../redux/thunks/authThunks';


const AccountForm = ({ handleSubmit, initialize, submitting }) => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);
  const { id, firstName, lastName, username, job, company } = user;

  useEffect(() => {

    initialize({
      firstName,
      lastName,
      username,
      job,
      company
    })
  }, [initialize])

  const buttonClassName = classNames('form__button', {
    'form__button--disabled': submitting,
  });

  const handleFormSubmit = (formValues) => {

    return dispatch(updateUser({ formValues, id: id }))
      .then(unwrapResult)
      .then(() => {
        dispatch(getUser())
        toastr.success('Succès!', 'Le compte a été mis à jour');
      })
      .catch(() => toastr.error('Oups!', 'Une erreur s\'est produite. Veuillez réessayer.'));
  }


  return (
    <form
      className="form__form"
      onSubmit={handleSubmit(handleFormSubmit)}
    >
      <p className='title'>Mettre à jour les informations de votre compte</p>
      <hr />
      <Field
        name="firstName"
        type="text"
        placeholder="Prénom"
        label="Prénom"
        component={Input}
        validate={[required]}
      />
      <Field
        name="lastName"
        type="text"
        placeholder="Nom"
        label="Nom"
        component={Input}
        validate={[required]}
      />
      <Field
        name="username"
        type="email"
        placeholder="Email"
        label="Email"
        component={Input}
        readOnly
      />
      <Field
        name="job"
        type="text"
        placeholder="Poste"
        label="Poste"
        component={Input}
        validate={[required]}
      />
      <Field
        name="company"
        type="text"
        placeholder="Entreprise"
        label="Entreprise"
        component={Input}
        validate={[required]}
      />
      <div className="form__button-wrapper">
        <div className="form__save-button-wrapper">
          <button disabled={submitting} className={buttonClassName}>
            Enregistrer
          </button>
          <Spinner loading={submitting} overlay />
        </div>
      </div>
    </form>
  )
}


export default reduxForm({ form: 'UserUpdateForm' })(AccountForm);