import React, { useEffect } from 'react';

import InputError from './InputError';

const RoomSelect = ({ rooms, meta, input, label, defaultValue, officeId }) => {
  const roomOptions = rooms.map((room) => (
    <option value={room['id']} key={room.id}>
      {room.title}
    </option>
  ));

  useEffect(() => {
    input.onChange(defaultValue)
  }, [officeId])

  return (
    <div>
      <label htmlFor="latName" className="form__label">
        {label}
      </label>
      <select
        value={input.value}
        className="form__input form__select"
        onChange={e => input.onChange(e.target.value)}
        onBlur={() => input.onBlur(input.value)}
      >
        <option></option>
        {roomOptions}
      </select>
      <InputError meta={meta} />
    </div>
  );
};

export default RoomSelect;
