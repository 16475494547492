import { createSlice, current } from '@reduxjs/toolkit';
import { toLocalString } from '../../helpers/dates';

import {
  getBookingsByOfficeId,
  book,
  closeBooking,
  cancelBooking,
  removeBooking,
  updateBooking,
} from '../thunks/bookingsThunks';
import { setLoadingOff, setLoadingOn } from './helpers';

const initialState = {
  list: [],
  isLoading: false
};

const bookingsSlice = createSlice({
  name: 'bookings',
  initialState,
  reducers: {
    removeBook: (state,{payload}) => {
      const index = state.list.findIndex(
        booking => booking.id === payload.bookingId
      );

      if (index !== -1) {
        state.list.splice(index, 1);
      }
    }
  },
  extraReducers: {
    [getBookingsByOfficeId.fulfilled]: (state, action) => {
      setLoadingOff(state);
      state.list = action.payload.data['hydra:member'];
    },
    [getBookingsByOfficeId.pending]: setLoadingOn,
    [getBookingsByOfficeId.rejected]: setLoadingOff,
    [book.pending]: setLoadingOn,
    [book.fulfilled]: (state, { payload }) => {
      setLoadingOff(state);
      const booking = payload.data;
      const eventData = {
        ...booking,
        resourceId: booking.room.id,
        start: toLocalString(booking.startDate),
        end: booking.endedAt
          ? toLocalString(booking.endedAt)
          : toLocalString(booking.endDate),
      };
      state.list.push(eventData);
    },
    [book.rejected]: setLoadingOff,
    [closeBooking.fulfilled]: (state, { payload }) => {
      const endedBooking = payload.data;
      const index = state.list.findIndex(
        booking => booking.id === endedBooking.id
      );

      if (index !== -1) {
        state.list[index] = endedBooking;
      }
    },
    [cancelBooking.fulfilled]: (state, { payload }) => {
      const index = state.list.findIndex(
        booking => booking.id === payload.data.id
      );

      if (index !== -1) {
        state.list.splice(index, 1);
      }
    },
    [removeBooking.fulfilled]:setLoadingOff,
    [updateBooking.fulfilled]: (state, { payload }) => {
      state.list = current(state.list).map(booking => {
        return booking.id === payload.data.id ? payload.data : booking;
      });
    }
  },
});

export const { removeBook } = bookingsSlice.actions;
export default bookingsSlice.reducer;
