import React, { useRef, useState, useEffect } from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import {getStartHour} from "../../helpers/dates";

const TimePicker = ({ time, setTime, minTime = getStartHour() }) => {
  const [isTimePickerOpen, setIsTimePickerOpen] = useState();
  const timePickerRef = useRef();

  useEffect(() => {
    const onWindowClick = e => {
      if (e.target === timePickerRef.current) {
        setIsTimePickerOpen(true);
      } else {
        setIsTimePickerOpen(false);
      }
    };

    window.addEventListener('click', onWindowClick);

    return () => window.removeEventListener('click', onWindowClick);
  }, [isTimePickerOpen]);

  return (
    <div
      ref={timePickerRef}
      onClick={() => setIsTimePickerOpen(!isTimePickerOpen)}
      className="book__time"
    >
      {moment(time).format('HH:mm')}
      <DatePicker
        selected={time}
        onChange={setTime}
        showTimeSelect
        showTimeSelectOnly
        timeFormat="HH:mm"
        timeIntervals={15}
        timeCaption=""
        open={isTimePickerOpen}
        minTime={minTime}
        maxTime={new Date().setHours(23,45)}
      />
      <i className='fa-solid fa-chevron-down'></i>
    </div>
  );
};

export default TimePicker;
