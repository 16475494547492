import React from 'react';
import './DeleteAccount.sass';

const DeleteAccount = () => {
  const email = "pierre@cosavostra.com";
  const subject = encodeURIComponent("Demande de suppression de compte");
  const body = encodeURIComponent(
    "Cher équipe de support,\n\nJe souhaite demander la suppression de mon compte de l'application de réservation de salles. Voici mes détails :\n\n- Mon nom : [Votre nom complet ici]\n- Mon e-mail : [Votre adresse e-mail ici]\n\nMerci de me faire savoir les prochaines étapes pour traiter cette demande.\n\n[Vous pouvez ajouter ici toute information supplémentaire ou question]\n\nCordialement,\n[Votre nom ici]"
  );

  return (
    <div className="delete-account-wrapper">
      <div className="delete-account">
        <h1>Supprimer mon compte</h1>
        <p>La suppression de votre compte est irréversible. Cela entraînera la perte de l'accès à vos réservations et données associées.</p>
        <p>Les demandes de suppression sont traitées manuellement pour maintenir l'ordre dans nos plannings de réservation. Pour supprimer votre compte, cliquez sur le bouton ci-dessous et complétez l'e-mail pré-rempli :</p>
        <div className="email-link">
          <a href={`mailto:${email}?subject=${subject}&body=${body}`} className="email-button" aria-label="Envoyer un e-mail au support pour supprimer votre compte">Envoyer un e-mail au support</a>
        </div>
        <p>Votre avis est précieux pour nous aider à améliorer nos services.</p>
      </div>
    </div>
  );
};

export default DeleteAccount;
