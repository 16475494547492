import React from 'react';
import { NavLink } from 'react-router-dom';

import './CustomLink.sass';

const CustomLink = ({ to, children,state }) => {
  return (
    <NavLink
      end
      to={to}
      className={navData =>
        navData.isActive ? 'custom-link custom-link--active' : 'custom-link'
      }
      state={state}
    >
      {children}
    </NavLink>
  );
};

export default CustomLink;
