import React from 'react';
import { useSelector } from 'react-redux';
import { isLength } from 'validator';

const PasswordsMatch = ({ formId, passwordFieldName = 'password' }) => {

  const values  = useSelector(state => state.form[formId]?.values);
  if (!values) {
    return null;
  }

  const { passwordConfirm } = values;

  if (!values[passwordFieldName] || !passwordConfirm) {
    return null;
  }

  if (isLength(values[passwordFieldName], { min: 5 }) && values[passwordFieldName] === passwordConfirm) {
    return (
      <div className="form__passwords-match">
        <i className="fa-solid fa-check"></i> Les mots de passe correspondent
      </div>
    );
  }

  return null;
};

export default PasswordsMatch;
