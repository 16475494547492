import { configureStore } from '@reduxjs/toolkit';
import { reducer as toastrReducer } from 'react-redux-toastr';
import { reducer as reduxFormReducer } from 'redux-form';

import officesReducer from './slices/officesSlice';
import authReducer from './slices/authSlice';
import usersReducer from './slices/usersSlice';
import bookingsReducer from './slices/bookingsSlice';
import roomsReducer from './slices/roomsSlice';

export default configureStore({
  reducer: {
    form: reduxFormReducer,
    toastr: toastrReducer,
    auth: authReducer,
    offices: officesReducer,
    users: usersReducer,
    bookings: bookingsReducer,
    rooms: roomsReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
